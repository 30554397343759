export const DASHBOARD_GRANTED_TIME = "@dashboard_gt";

export interface IJobFeedback {
  steps: {
    title: string,
    context?: {
      status: 'pending' | 'success' | 'error' | 'waiting',
    }
    stepLength?: any,
  }[],
  description: string,
}
