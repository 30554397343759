import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ConfirmationPopup, { ConfirmationPopupRef } from '../layout/ConfirmationPopup';
import { getPendingPayments } from '../../hooks/usePayments';
import { Payment } from '../../dtos/payment';
import { useQuery, useMutation } from 'react-query';
import { QueryKey } from '../../services/api/query';
import { getPayments } from '../../services/paymentService';
import { ApiError } from '../../services/api/api';
import { View, Text } from "react-native";
import Snackbar from '../atoms/Snakbar';
import { useToast } from 'react-native-toast-notifications';
import { getJob } from '../../services/jobService';
import { deleteUserConsent } from '../../services/bankService';
import { Job, JobStatus } from '../../dtos/job';
import { User } from '../../dtos/user';
import { getUser } from '../../services/userService';
import { useUser } from '../../context/userContext';
import { getActiveConnections, generateNewConsentLink } from '../../services/bankService';
import { DASHBOARD_GRANTED_TIME, IJobFeedback } from '../../constants/Common';
import AsyncStorage from "@react-native-async-storage/async-storage";

const DeleteConsentSteps: IJobFeedback = {
  steps: [
    {
      title: 'Deleting bank connection',
      context: {
        status: 'waiting',
      }
    },
    {
      title: 'Verifying deletion',
      context: {
        status: 'waiting',
      },
      stepLength: '170px'
    }
  ],
  description: "We are un-linking your financial institution.",
}

const ActivateConsentSteps: IJobFeedback = {
  steps: [
    {
      title: 'Creating new consent',
      context: {
        status: 'waiting',
      }
    },
  ],
  description: "We are linking your financial institution.",
}


const ConsentUpdate: React.ForwardRefRenderFunction<ConfirmationPopupRef, {}> = ({ }, ref) => {

  const toast = useToast();
  const { user } = useUser();
  const ConfirmRefModal = useRef<ConfirmationPopupRef>(null);

  const [pendingPaymentCount, setPendingPaymentCount] = useState<number>();
  const [userConsentURL, setUserConsentURL] = useState<string>('');

  const [isConsentActivated, setIsConsentActivated] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isDeletingConsent, setIsDeletingConsent] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  let [activeExecutionCounter, setActiveExecutionCounter] = useState(0);

  let [loaderSteps, setLoaderSteps]: any = useState<IJobFeedback | null>(null);


  useImperativeHandle(ref, () => ({
    show: () => {
      setIsDataLoading(true);
      setShowSuccessDialog(false);
      let tempSteps: any = {};
      if (user && user?.isDeactivated) {
        tempSteps = { ...ActivateConsentSteps };
        tempSteps.steps[0].context.status = 'pending';
      }
      else {
        tempSteps = { ...DeleteConsentSteps };
        tempSteps.steps[0].context.status = 'pending';
      }
      setLoaderSteps({ ...tempSteps });
      ConfirmRefModal.current?.show();
    },
    hide: () => {
      handleConsentCancel();
    },
  }));

  let isJobStatusFailed = (user?.basiqJobStatus &&
    user?.basiqJobStatus?.length > 0 &&
    user?.basiqJobStatus.filter((job) => job.status === "failed").length > 0) || false;

  useEffect(() => {
    if (user?.isDeactivated) {
      setIsConsentActivated(false);
      setUserConsentURL(user?.consent);
      setLoaderSteps({ ...ActivateConsentSteps });
    }
    else {
      setIsConsentActivated(true);
      setLoaderSteps({ ...DeleteConsentSteps });
    }
  }, [user]);

  const handleConsentCancel = () => {
    setShowSuccessDialog(false);
    setPendingPaymentCount(0);
    setIsDataLoading(false);
    setIsDeletingConsent(false);
    setLoaderSteps(null);
  }

  // Consent deactivation request
  const { mutate: handleDeleteConsent, data: DeleteConsentStatus } = useMutation(deleteUserConsent, {
    onError: () => {
      toast.show("Something went wrong. Please try again later", {
        type: "danger",
      });
      ConfirmRefModal.current?.hide();
      setIsDeletingConsent(false);
    },
    onSuccess: async () => {
      let tempSteps: any = { ...DeleteConsentSteps }
      tempSteps.steps[0].context.status = 'success';
      tempSteps.steps[1].context.status = 'pending';
      setLoaderSteps({ ...loaderSteps });
      setActiveExecutionCounter(3);
      try {
        await AsyncStorage.setItem(
          DASHBOARD_GRANTED_TIME,
          `${new Date().toISOString()}`
        );
      } catch (e) {
        // saving error
      }
    },
  });

  // Gettings pending payments count
  const { } = useQuery<Payment[] | null, ApiError>({
    queryKey: [QueryKey.Payments],
    queryFn: () => getPayments(),
    enabled: isConsentActivated && isDataLoading,
    onSuccess: (data: any) => {
      let payments: any = getPendingPayments(data);
      setPendingPaymentCount(payments.length || 0);
      if (isConsentActivated) {
        setIsDataLoading(false);
      }
    },
    onError: (error: any) => {
      toast.show("Something went wrong", {
        type: "danger",
      });
      setIsDataLoading(false);
    }
  });

  // create bank connection API
  let { data: BankConnections } = useQuery({
    queryKey: [QueryKey.BankConnections],
    queryFn: () => getActiveConnections(),
    enabled: !isConsentActivated && isDataLoading,
    onSuccess: (data) => {
      if (data.length && !isJobStatusFailed) {
        setIsDataLoading(false);
      }
      else {
        setActiveExecutionCounter(1);
      }
    },
    onError: () => {
      toast.show("Something went wrong", {
        type: "danger",
      });
      setIsDataLoading(false);
    }
  });

  // Generate new consent link request
  const { data: ConsentLinkStatus, isFetched: newConsentCreated } = useQuery({
    enabled: (BankConnections?.length === 0 || isJobStatusFailed) && activeExecutionCounter === 1,
    queryFn: () => generateNewConsentLink(),
    queryKey: [QueryKey.GenerateNewConsent],
    onError: () => {
      toast.show("Something went wrong. Please try again later", {
        type: "danger",
      });
      ConfirmRefModal.current?.hide();
    },
    onSuccess: () => {
      let tempSteps: any = { ...DeleteConsentSteps }
      tempSteps.steps[0].context.status = 'success';
      tempSteps.steps[1].context.status = 'success';
      if (ConsentLinkStatus?.consent) {
        setUserConsentURL(ConsentLinkStatus?.consent);
      }
      setIsDataLoading(false);
      setActiveExecutionCounter(0);
      setLoaderSteps({ ...loaderSteps });
    }
  });

  const { } = useQuery<User | null, ApiError>({
    queryKey: [QueryKey.User],
    queryFn: () => getUser(),
    enabled: activeExecutionCounter === 3,
    onSuccess: async (data: any) => {
      if (data?.externalUserId && data.consent) {
        setUserConsentURL(data.consent);
      }
      if (isConsentActivated) {
        if (loaderSteps && loaderSteps.steps && loaderSteps.steps.length > 1) {
          loaderSteps.steps[1].context.status = JobStatus.Success;
        }
        setIsDeletingConsent(false);
        setShowSuccessDialog(true);
      }
      else {
        if (loaderSteps)
          loaderSteps.steps[1].context.status = JobStatus.Success;
        setIsDataLoading(false);
        setActiveExecutionCounter(0);
      }
      setLoaderSteps({ ...loaderSteps });
    },
    onError: () => {
      toast.show("Something went wrong", {
        type: "danger",
      });
      setIsDataLoading(false);
    }
  });


  const handleConsentUpdate = async () => {
    if (isConsentActivated) {
      setIsDeletingConsent(true);
      let tempSteps: any = { ...DeleteConsentSteps }
      tempSteps.steps[0].context.status = 'pending';
      tempSteps.steps[1].context.status = 'waiting';
      setLoaderSteps({ ...loaderSteps });
      handleDeleteConsent();
    }
    else if (!showSuccessDialog && !isConsentActivated) {
      await AsyncStorage.removeItem(DASHBOARD_GRANTED_TIME);
      window.open(userConsentURL, "_self");
    }
    if (showSuccessDialog) {
      handleConsentCancel();
      ConfirmRefModal.current?.hide();
    }
  }


  return (
    <ConfirmationPopup
      ref={ConfirmRefModal}
      title={!showSuccessDialog ? `${isConsentActivated ? "Un-Link " : "Link "}` + "Financial Institution" : 'Financial Institution Un-Linked'}
      sequentialLoader={loaderSteps}
      message={
        <View>
          {pendingPaymentCount && isConsentActivated && !showSuccessDialog ?
            <Snackbar
              message={
                <Text>
                  Warning! You have {pendingPaymentCount} pending payments.<br />Consider them before deactivating consent.
                </Text>
              }
              isClosable={false}
              type="warning"
              containerStyle={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
                textAlign: 'left',
              }}
            />
            : null
          }

          <Text style={{ paddingTop: '6px' }}>{!showSuccessDialog ? `Are you sure you want to ${isConsentActivated ? "un-link your" : "link your"} financial institution?` : 'Your financial institution has been un-linked successfully'}</Text>
        </View>
      }
      onCancel={handleConsentCancel}
      onConfirm={handleConsentUpdate}
      confirmButtonType={!showSuccessDialog ? isConsentActivated ? "alert" : "success" : 'success'}
      confirmButtonText={!showSuccessDialog ? isConsentActivated ? "Un-Link" : "Link" : 'Okay'}
      isLoading={isDataLoading || isDeletingConsent}
      isDefaultLoader={isDataLoading && isConsentActivated}
      hideCancelButton={!showSuccessDialog ? false : true}
    />
  );
};

export default forwardRef(ConsentUpdate);

