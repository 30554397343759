import styled from "styled-components/native";

type Props = {
  center?: boolean;
};

export const MediumText = styled.Text<Props>`
  padding-bottom: 10px;
  text-align: ${props => (props.center ? "center" : "left")};
  font-family: "Open Sans";
`;
