import { NavigationProp } from "@react-navigation/native";
import React from "react";
import { HeaderBackButton } from "@react-navigation/stack";
import { WelcomeScreen } from "../components/screens/Onboarding/Welcome/WelcomeScreen";
import { HelpScreen } from "../components/screens/Shared/Help/HelpScreen";
import { PrivacyPolicyScreen } from "../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { TermsScreen } from "../components/screens/Shared/Terms/TermsScreen";
import { ConnectBankScreen } from "../components/screens/Onboarding/ConnectBank/ConnectBankScreen";
import { RoundupAccountsSetupScreen } from "../components/screens/Onboarding/RoundupAccountsSetup/RoundupAccountsSetupScreen";
import { FundingAccountSetupScreen } from "../components/screens/Onboarding/FundingAccountSetup/FundingAccountSetupScreen";
import { FinishOnboardingScreen } from "../components/screens/Onboarding/FinishOnboarding/FinishOnboardingScreen";
import { NavigationScreen, Options } from "./shared";

export type SetupScreensParamList = {
  AccountSetup: undefined;
  ConnectBank: undefined;
  RoundupAccountsSetup: undefined;
  FundingAccountSetup: undefined;
  FinishOnboarding: undefined;
  Help: undefined;
  PrivacyPolicy: undefined;
  Terms: undefined;
};

export interface SetupNavigationScreen extends NavigationScreen {
  name: keyof SetupScreensParamList;
}

export const setupScreens: SetupNavigationScreen[] = [
  {
    name: "AccountSetup",
    component: ConnectBankScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "ConnectBank",
    component: ConnectBankScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "RoundupAccountsSetup",
    component: RoundupAccountsSetupScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "FundingAccountSetup",
    component: FundingAccountSetupScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "FinishOnboarding",
    component: FinishOnboardingScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "Terms",
    component: TermsScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }): Options => ({
      title: "T&C's",
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }): Options => ({
      title: "Privacy Policy",
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
  {
    name: "Help",
    component: HelpScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<SetupScreensParamList>;
    }): Options => ({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
];
