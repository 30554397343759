import React from "react";
import { useMutation } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { View } from "react-native";
import { t } from "react-native-tailwindcss";
import { useToast } from "react-native-toast-notifications";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import { useUser } from "../../../../context/userContext";
import { CreateUserParams, updateUser } from "../../../../services/userService";
import { User } from "../../../../dtos/user";
import { getErrorMessage } from "../../../../services/api/api";
import Card from "../../../atoms/Card";
import { MediumText } from "../../../atoms/MediumText";

const fields: {
  key: keyof CreateUserParams;
  placeholder: string;
}[] = [
    {
      key: "firstName",
      placeholder: "First Name",
    },
    {
      key: "lastName",
      placeholder: "Last Name",
    },
    {
      key: "mobile",
      placeholder: "Mobile",
    },
  ];

const UserForm = ({ preloadedData }: { preloadedData?: CreateUserParams }) => {
  const toast = useToast();
  const { handleSubmit, control } = useForm({
    defaultValues: preloadedData,
  });

  const mutation = useMutation(updateUser, {
    onSuccess: (data) => {
      toast.show("Your profile has been updated", {
        type: "success",
      });
    },
    onError: (error: any) => {
      toast.show("Something went wrong", {
        type: "danger",
      });
    },
  });

  const onSubmit = async (data: CreateUserParams) => {
    if (preloadedData?.country) {
      data.country = preloadedData?.country
    }
    mutation.mutate(data);
  };

  return (
    <>
      {fields.map(field => (
        <Controller
          key={field.key}
          name={field.key}
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <Input
              onChangeText={(text: string) => onChange(text)}
              value={value}
              placeholder={field.placeholder}
            />
          )}
        />
      ))}
      <Button onPress={handleSubmit(onSubmit)} label="Update" disabled={mutation.isLoading} />
    </>
  );
};
export const ProfileScreen = (): React.ReactElement => {
  const { user } = useUser();

  return (
    <Card style={styles.card}>
      <View>
        <MediumText center style={{ fontWeight: "600" }}>
          Profile Settings
        </MediumText>
      </View>
      <View style={styles.container}>
        <Input value={user?.email} editable={false} />
        <UserForm preloadedData={user as User} />
      </View>
    </Card>
  );
};

const styles = {
  card: [t.flex, t.p5, t.flexCol, t.bgWhite, t.shadowXl],
  container: [t.flex1, t.p6],
};
