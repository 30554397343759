import React, { FunctionComponent } from "react";
import {
  createStackNavigator,
  StackScreenProps,
} from "@react-navigation/stack";

import { WelcomeRootScreen } from "../../components/screens/Onboarding/Welcome/WelcomeRootScreen";
import { TermsScreen } from "../../components/screens/Shared/Terms/TermsScreen";
import { PrivacyPolicyScreen } from "../../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { HelpScreen } from "../../components/screens/Shared/Help/HelpScreen";

const basiqScreenList: IScreenRoute[] = [
  {
    name: "Terms",
    component: TermsScreen,
    options: {
      title: "Terms & Conditions",
    },
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    options: {
      title: "Privacy Policy",
    },
  },
  {
    name: "Help",
    component: HelpScreen,
    options: {
      title: "Help",
    },
  },
];

const BasiqConsentStackRoot =
  createStackNavigator<BasiqConsentRootStackParamList>();

export const BasiqConsentStack: FunctionComponent<
  StackScreenProps<RootStackParamList, "BasiqConsent">
> = ({ route }) => {
  const user = route.params;

  return (
    <BasiqConsentStackRoot.Navigator
      initialRouteName="Welcome"
      screenOptions={{ headerShown: false }}
    >
      <BasiqConsentStackRoot.Screen
        name={"Welcome"}
        component={WelcomeRootScreen}
        options={{ title: "Welcome" }}
        initialParams={{
          user: JSON.stringify(user),
        }}
      />

      {basiqScreenList.map(({ name, component, options }, index) => (
        <BasiqConsentStackRoot.Screen
          key={index}
          name={name as keyof BasiqConsentRootStackParamList}
          component={component}
          options={options}
        />
      ))}

    </BasiqConsentStackRoot.Navigator>
  );
};
