import { Ionicons } from "@expo/vector-icons";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import React from "react";
import { TouchableOpacity } from "react-native";
import { t } from "react-native-tailwindcss";
import { AppScreensParamList } from "../../navigation/app-screens";

type Props = {
  navigation: DrawerNavigationProp<AppScreensParamList>;
  white?: boolean;
};

const MenuButton = ({ navigation, white }: Props): React.ReactElement => {
  const style = [t.mL3];
  if (white) {
    style.push(t.textWhite);
  }

  return (
    <TouchableOpacity onPress={() => navigation.openDrawer()}>
      <Ionicons name="menu" size={30} style={style} />
    </TouchableOpacity>
  );
};

export default MenuButton;
