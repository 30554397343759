import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Colors from '../../constants/Colors';
import { MediumText } from './MediumText';
import { AntDesign } from '@expo/vector-icons';
import { LargeText } from './LargeText';
import { appConfig } from '../../config/config';

interface SnackbarProps {
    type: 'primary' | 'warning' | 'alert' | 'success';
    message: any;
    isClosable: boolean;
    onClose?: Function;
    containerStyle?: any,
}

const Snackbar: React.FC<SnackbarProps> = ({ message, type, isClosable = true, onClose = () => { }, containerStyle = {} }) => {
    const [visible, setVisible] = useState(true);

    const handleClose = () => {
        setVisible(false);
        onClose();
    };

    if (!visible) {
        return null; // Render nothing if the snackbar is not visible
    }

    const getSnackbarColors = () => {
        switch (type) {
            case 'warning':
                return appConfig.warningColor;
            case 'alert':
                return appConfig.alertColor;
            case 'success':
                return appConfig.successColor;
            default:
                return appConfig.primaryColour;
        }
    }

    return (
        <View style={{ backgroundColor: getSnackbarColors() }}>
            <View style={[styles.container, containerStyle]}>
                <Text style={[styles.message, type === 'alert' ? { color: 'red' } : {}]}>
                    {message}
                </Text>
                {isClosable ?
                    <View style={styles.closeButton}>
                        <AntDesign onPress={handleClose} name="closecircleo" size={20} />
                    </View>
                    : null
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    message: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: '400',
    },
    closeButton: {
        display: 'flex',
        paddingLeft: '8px',
        justifyContent: 'center',
    },
});

export default Snackbar;
