import React, { ReactNode, useContext, useState } from "react";
import { useQuery } from "react-query";
import { useError } from "../context/errorContext";
import { ApiError } from "../services/api/api";
import { QueryKey } from "../services/api/query";
import { getUser } from "../services/userService";
import { getAllClubs } from "../services/clubService";
import { Club } from "../dtos/job";

type ClubContext = {
    clubList?: Club[] | null;
    error?: ApiError | null;
    isClubsLoading: boolean;
};

const ClubContext = React.createContext<ClubContext>({} as ClubContext);

const ClubsProvider = ({
    children,
}: {
    children: ReactNode;
}): React.ReactElement => {

    const { setCriticalError } = useError();
    const useUserQuery = useQuery<Club[] | null, ApiError>(
        [QueryKey.Club],
        () => getAllClubs(),
        {
            onError: (e) => setCriticalError(e)
        }
    );

    return (
        <ClubContext.Provider
            value={{
                clubList: useUserQuery.data,
                error: useUserQuery.error,
                isClubsLoading: useUserQuery.isLoading,

            }}
        >
            {children}
        </ClubContext.Provider>
    );
};

const useClubList = (): ClubContext => useContext(ClubContext);
export { ClubsProvider, useClubList };
