import React, { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { userCredsRememberObj, loginType, userLoginType } from '../../../../dtos/user';


export type FormData = {
    email: string;
    password: string;
    isRememberMe: boolean;
    loginType?: userLoginType;
    firebaseToken?: string;
    isLoggedIn: boolean;
};

const secretPass = userCredsRememberObj.saveUserCredsSecretPass;
const itemKey = userCredsRememberObj.saveUserItemKey;
const loginTypeKey = loginType;

const encryptData = (text: string) => {
    return CryptoJS.AES.encrypt(
        JSON.stringify(text),
        secretPass
    ).toString();

};

const decryptData = (text: string) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data
};

export const saveLoginData = (loginData: FormData) => {
    if (loginData.isRememberMe || (loginData.loginType == userLoginType.sso)) {
        const loginDataEncrypted = encryptData(JSON.stringify(loginData));
        localStorage.setItem(itemKey, loginDataEncrypted);
    } else {
        localStorage.setItem(itemKey, "");
    }

};

export const retrieveLoginData = () => {
    const loginDataEncrypted = localStorage.getItem(itemKey);
    if (loginDataEncrypted && loginDataEncrypted != "") {
        const logindataDecrypted = decryptData(loginDataEncrypted.toString());
        return JSON.parse(logindataDecrypted);
    }
};

export const saveLoginType = (loginType: userLoginType) => {
    localStorage.setItem(loginTypeKey, loginType);
};

export const retreiveLoginType = (loginType: userLoginType) => {
    localStorage.setItem(loginTypeKey, loginType);
};