import React from 'react';
import Constants from 'expo-constants';
import { ScrollView } from 'react-native-gesture-handler';
import { ScreenContainer } from '../../../layout/ScreenContainer';
import { LineTitle } from '../../../molecules/LineTitle';
import { SiporaPrivacyPolicy } from './SiporaPrivacyPolicy';

export const PrivacyPolicyScreen = (): React.ReactElement => {
  return (

    <ScrollView>
      <ScreenContainer>
        <LineTitle title="Sipora Privacy Policy" />
        <SiporaPrivacyPolicy />
      </ScreenContainer>
    </ScrollView>

  );
};
