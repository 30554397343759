import React from "react";
import { ActivityIndicator, View, Text, StyleSheet, Image } from "react-native";
import { t } from "react-native-tailwindcss";
import styled from "styled-components/native";

import { ScreenContainer } from "../../../layout/ScreenContainer";
import { MediumText } from "../../../atoms/MediumText";
import { LargeText } from "../../../atoms/LargeText";
import { Footer } from "../../../molecules/Footer";
import { HCenterStack } from "../../../layout/HStack";
import { VCenterStack } from "../../../layout/VStack";
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { AuthScreensParamList } from '../../../../navigation/auth-screens';
import verifyEmail from "../../../../assets/images/verifyEmail.png";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;
// In this screen we handle the error scenarios
export const EmailVerifyScreen = (): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<AuthScreensParamList>>();

  return (
    <ScreenContainer style={{ height: '100%' }} center>
      <View style={styles.imageContainer}>
        <View style={{ width: "50%" }}>
          <Image
            source={verifyEmail}
            style={{
              width: "100%",
              height: "100%",
              resizeMode: "contain",
            }}
          />
        </View>
      </View>

      <VCenterStack>
        <LargeText center style={{ fontSize: 23, fontWeight: '400', color: '#5c5f6f' }}>Email Verification Link</LargeText>
        <MediumText center style={styles.text}>We sent an email verification link to your email address.</MediumText>
        <MediumText center style={styles.text}>Please verify your email address.</MediumText>
        <MediumText
          center
          style={styles.text}
        >
          If your email has been verified already please
          <Text style={[t.textBlue400]}
            onPress={() => navigation.navigate("Login")}
          >
            {" "}click here
          </Text>
          <Text>
            {" "}to sign in
          </Text>
        </MediumText>
      </VCenterStack>

      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    paddingHorizontal: 70,
    paddingVertical: 20
  },
  imageContainer: {
    width: '100%',
    height: 200,
    marginBottom: 30,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 20,
    color: '#5c5f6f'
  },
});
