import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { View, Modal, StyleSheet, ActivityIndicator } from 'react-native';
import { MediumText } from '../atoms/MediumText';
import { LargeText } from '../atoms/LargeText';
import RoundedButtons from '../atoms/RoundedButtons';
import { appConfig } from '../../config/config';
import { JobFeedback } from '../organisms/JobFeedback';

interface ConfirmationPopupProps {
    title: string;
    message: any;
    confirmButtonType: 'alert' | 'default' | 'warning' | 'success';
    confirmButtonText: string;
    onCancel: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
    sequentialLoader?: any;
    isDefaultLoader?: boolean;
    hideCancelButton?: boolean;
}

export interface ConfirmationPopupRef {
    show: () => void;
    hide: (value?: any) => void;
}

const ConfirmationPopup: React.ForwardRefRenderFunction<ConfirmationPopupRef, ConfirmationPopupProps> = (
    { title, message, onCancel, onConfirm, confirmButtonType, confirmButtonText, isLoading, hideCancelButton, sequentialLoader = {}, isDefaultLoader },
    ref
) => {
    const [isVisible, setIsVisible] = useState(false);
    const [loaderSteps, setLoaderSteps]: any = useState(null)

    useImperativeHandle(ref, () => ({
        show: () => {
            setIsVisible(true);
        },
        hide: () => {
            onPopUpDismiss();
        },
    }));

    const onPopUpDismiss = () => {
        setIsVisible(false);
        onCancel();
    }

    const getButtonColors = () => {
        switch (confirmButtonType) {
            case 'warning':
                return appConfig.warningColor;
            case 'alert':
                return appConfig.alertColor;
            case 'success':
                return appConfig.successColor;
            default:
                return appConfig.primaryColour;
        }
    }
    useEffect(() => {
        setLoaderSteps(sequentialLoader)
    }, [sequentialLoader])

    return (
        <Modal
            visible={isVisible}
            transparent={true}
            animationType="fade"
            onDismiss={onPopUpDismiss}
        >
            <View style={styles.container}>
                <View style={styles.overlay} />
                <View style={styles.popup}>
                    <LargeText center style={styles.title}>
                        {title}
                    </LargeText>
                    {
                        isLoading ?
                            isDefaultLoader || !loaderSteps
                                ? <ActivityIndicator style={styles.activityIndicatorContainer} />
                                : <View><JobFeedback steps={loaderSteps?.steps} description={loaderSteps?.description || ''} /></View>
                            : <>
                                <MediumText center style={styles.message}>
                                    {message}
                                </MediumText>
                                <View style={styles.buttonContainer}>
                                    {hideCancelButton
                                        ? null
                                        : <RoundedButtons onPress={onPopUpDismiss} disabled={isLoading} label='Cancel' />
                                    }
                                    <View style={{ marginLeft: '8px' }}></View>
                                    <RoundedButtons colour={getButtonColors()}
                                        disabled={isLoading}
                                        onPress={onConfirm} label={confirmButtonText} />
                                </View>
                            </>
                    }
                </View>
            </View>
        </Modal >
    );
};

const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    },
    container: {
        position: 'relative',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

    },
    // container: [t.flex1, t.justifyCenter, t.alignCenter, t.m2],
    popup: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 42,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 444,
        minHeight: 228,
    },
    activityIndicatorContainer: {
        paddingTop: 32,
    },
    title: {
        fontWeight: '600',
        marginBottom: 10,
    },
    // title: [t.fontBold, t.mB10],
    message: {
        marginBottom: 20,
    },
    // message: [t.mB20],
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    // buttonContainer: [t.flexRow, t.justifyEvenly],
});

export default forwardRef(ConfirmationPopup);
