import { appConfig } from "../config/config";
import api from "./api/api";
import utils from "../utils";
import { Club, UpdateClub } from "../dtos/job";

export const getAllClubs = async () => {
    const hasAuthToken = await utils.hasAuthToken();
    if (!hasAuthToken) return null;
    return api.get<Club[]>(
        `common/${appConfig.tenant}/clubs`
    );
};

export const UpdateClubForUser = async ({
    supportingClubId
}: UpdateClub) => {
    return api.put<UpdateClub>(
        `user/${appConfig.tenant}/user/supporting-club`,
        {
            supportingClubId
        }
    );
};