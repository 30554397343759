import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { t } from "react-native-tailwindcss";
import Card from "../../../atoms/Card";
import { HCenterStack } from "../../../layout/HStack";
import { MediumText } from "../../../atoms/MediumText";
import Button from "../../../atoms/Button";
import { ConfirmationPopupRef } from "../../../layout/ConfirmationPopup";
import { appConfig } from "../../../../config/config";
import ConsentUpdate from "../../../organisms/ConsentUpdate";
import { useUser } from "../../../../context/userContext";


const ManageConsent = () => {

    const { user } = useUser();

    const confirmDeactivationDialogRef = useRef<ConfirmationPopupRef>(null);
    const [isConsentDeactivated, setIsConsentDeactivated] = useState<boolean>(false);


    const showConfirmationDialog = () => {
        confirmDeactivationDialogRef.current?.show();
    }

    useEffect(() => {
        if (user?.email)
            setIsConsentDeactivated(user?.isDeactivated || false)
    }, [user]);

    return (
        <Card style={styles.card}>
            <View>
                <MediumText center style={{ fontWeight: "600" }}>
                    {'Link Financial Institution'}
                </MediumText>
            </View>
            <HCenterStack style={[t.p3]}>
                <View style={{ width: '85%' }}>
                    <Button
                        label={isConsentDeactivated ? "Link Financial Institution" : "Un-Link Financial Institution"}
                        onPress={showConfirmationDialog}
                        colour={isConsentDeactivated ? appConfig.successColor : appConfig.alertColor}
                    />
                </View>
            </HCenterStack>
            <ConsentUpdate
                ref={confirmDeactivationDialogRef}
            />
        </Card>
    )
}

const styles = {
    card: [t.flex, t.flexCol, t.p6, t.mB6, t.bgWhite, t.shadowXl, t.mT6],
};

export default ManageConsent;