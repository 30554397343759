import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import { Picker } from '@react-native-picker/picker';
import { Club } from "../../dtos/job";
import { t } from "react-native-tailwindcss";

interface Props {
  items: Club[];
  onValueChange: (value: string) => void;
  placeholder?: string | null;
  selectedValue?: string | null;
  style?: ViewStyle;
}
export const Dropdown = ({
  items,
  onValueChange,
  placeholder,
  selectedValue,
  style
}: Props): React.ReactElement => {
  return (
    <View style={style}>
      <Picker
        selectedValue={selectedValue ?? undefined}
        onValueChange={onValueChange}
        style={[
          t.bgWhite,
          t.h12,
          t.selfStretch,
          t.p2,
          t.rounded,
          t.textBase,
          t.textGray600,
          t.shadowLg,
          t.border0]}>
        {placeholder &&
          <Picker.Item label={placeholder} value={null} />
        }
        {items.map((item) => {
          return (<Picker.Item label={item.name} value={item._id} key={item._id} />)
        })}
      </Picker>
    </View>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    color: "black",
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: "purple",
    borderRadius: 8,
    color: "black",
  },
  inputWeb: {
    fontSize: 16,
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderWidth: 0,
    borderColor: "#6b7280",
    borderRadius: 8,
    color: "inherit",
  }
});
