import { appConfig } from "../config/config";
import { Payment } from "../dtos/payment";
import api from "./api/api";

export type StaticContent = {
  name: string;
  link: string;
};

export const payFixedAmount = async (amount: number): Promise<void> => {
  return api.post<void>(`payment/${appConfig.tenant}/user/payFixedAmount`, {
    amount,
  });
};

export const getPayments = async (): Promise<Payment[]> => {
  return api.get<Payment[]>(`payment/${appConfig.tenant}/user/payments`);
};

export const getStaticContent = async (): Promise<StaticContent[]> => {
  return api.get<StaticContent[]>(
    `payment/${appConfig.tenant}/user/contents/static`,
  );
};
