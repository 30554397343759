import React, { useEffect, FunctionComponent, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToast } from "react-native-toast-notifications";
import Input from "../../../molecules/Input";
import Button from "../../../atoms/Button";
import {
  linkedSavingsAccount,
  getLinkedSavingsAccount,
} from "../../../../services/userService";
import { QueryKey } from "../../../../services/api/query";
import {
  convertErrorCodeToErrorMessage,
  ErrorCode,
} from "../../../../services/api/api";
import { LinkAccount } from "../../../../dtos/job";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { useFocusEffect } from "@react-navigation/core";

type FormData = {
  accountName: string;
  bsb: string;
  accountNumber: string;
};

type LinkedSavingsAccountProps = {} & DrawerScreenProps<
  AuthenticatedRootStackParamList,
  "LinkedSavingsAccount"
>;
export const LinkedSavingsAccount: FunctionComponent<
  LinkedSavingsAccountProps
> = ({ navigation }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: linkedData } = useQuery<LinkAccount>(
    [QueryKey.LinkedSavingAccount],
    () => getLinkedSavingsAccount(),
    {
      onError: (error: any) => {
        if (error) {
          toast.show(error.message, {
            type: "danger",
          });
        }
      },
    }
  );

  const { mutate, isLoading } = useMutation(linkedSavingsAccount, {
    onSuccess: (data) => {
      toast.show("Saved data", {
        type: "success",
      });
      queryClient.invalidateQueries(QueryKey.LinkedSavingAccount);
      navigation.navigate("Dashboard");
    },
    onError: (error: any) => {
      if (error) {
        toast.show(error.message, {
          type: "danger",
        });
      }
    },
  });

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      accountName: linkedData?.accountName ?? "",
      bsb: linkedData?.bsb ?? "",
      accountNumber: linkedData?.accountNumber ?? "",
    },
    shouldUnregister: false,
  });

  const onSubmit = async ({ accountName, bsb, accountNumber }: FormData) => {
    mutate({ bsb, accountName, accountNumber });
    // QueryKey.LinkedSavingAccount
  };

  useEffect(() => {
    if (linkedData) {
      control.setValue("accountName", linkedData?.accountName ?? "");
      control.setValue("bsb", linkedData?.bsb ?? "");
      control.setValue("accountNumber", linkedData?.accountNumber ?? "");
    }
  }, [control, linkedData]);

  const isNumber = (value: string) => {
    const regexp = new RegExp('^\\d+$');
    return regexp.test(value);
  }

  useFocusEffect(useCallback(() => {
    clearErrors();
  }, []));
  console.log(errors);

  return (
    <>
      <Controller
        name="accountName"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.accountName?.message ?? undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="Acc. Name"
          />
        )}
        rules={{
          required: "Account Name is required",
        }}
      />
      <Controller
        name="bsb"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.bsb?.message ?? undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="BSB"
          />
        )}
        rules={{
          required: "BSB code is required",
          validate: (value) => {
            if (value && value.length !== 6) {
              return "Invalid lenght for BSB code";
            } else if (!isNumber(value)) {
              return "Invalid format for BSB Code, Value should be a number";
            }
            return true;
          },
        }}
      />
      <Controller
        name="accountNumber"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.accountNumber?.message ?? undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="Acc. #"
          />
        )}
        rules={{
          required: "Account Number is required",
          validate: (value) => {
            if (!isNumber(value)) {
              return "Invalid format for Account Number, Value should be a number";
            }
            return true;
          }
        }}
      />
      <Button onPress={handleSubmit(onSubmit)} disabled={isLoading} label="CONTINUE" />
    </>
  );
};
