export type Payment = {
  _id: string;
  tenant: string;
  tenantName: string;
  userId: string;
  description: string;
  totalDebitAmount: number;
  _createdDate: string;
  _flags: {
    active: boolean;
  };
  _deleted: false;
  _updatedDate: string;
  paymentData: {
    ref: string;
    initiator_id: string;
    your_bank_account_id: string;
    authoriser_id: string;
    authoriser_contact_id: string;
    schedule_ref: string | null;
    status: string;
    status_reason: string | null;
    matures_at: string;
    responded_at: string;
    created_at: string;
    credit_ref: string;
    payout: {
      amount: number;
      description: string;
      matures_at: string;
    };
    metadata: {
      paymentId: string;
    };
  };
  status: Status;
  transactionList: string[];
  amount: number; // only populated once the payment is approved
  bank_account_id: string;
  bank_ref: null;
  category: string;
  channels: string[];
  cleared_at: null;
  created_at: string;
  current_channel: string;
  matures_at: string;
  metadata: {
    paymentId: string;
  };
  parent_ref: string;
  party_bank_ref: null;
  party_contact_id: string;
  party_name: string;
  party_nickname: string | null;
  ref: string;
  status_changed_at: string;
  type: string;
};

export enum TransactionStatus {
  MATURING = "maturing",
  MATURED = "matured",
  PREPROCESSING = "preprocessing",
  PROCESSING = "processing",
  CLEARING = "clearing",
  CLEARED = "cleared",
  REJECTED = "rejected",
  RETURNED = "returned",
  VOIDED = "voided",
  PENDING_VERIFICATION = "pending_verification",
  PAUSED = "paused",
  PREFAILED = "prefailed",
  CHANNEL_SWITCHED = "channel_switched",
}

export const successAndPendingTransactionStatuses = [
  TransactionStatus.MATURING,
  TransactionStatus.MATURED,
  TransactionStatus.PREPROCESSING,
  TransactionStatus.CLEARING,
  TransactionStatus.CLEARED,
  TransactionStatus.PENDING_VERIFICATION,
  TransactionStatus.PAUSED,
];

export const failedTransactionStatuses = [
  TransactionStatus.REJECTED,
  TransactionStatus.RETURNED,
  TransactionStatus.VOIDED,
  TransactionStatus.PREFAILED,
  TransactionStatus.CHANNEL_SWITCHED,
];

export enum PaymentStatus {
  PENDING_APPROVAL = "pending_approval",
  APPROVED = "approved",
  UNVERIFIED = "unverified",
  DECLINED = "declined",
  CANCELLED = "cancelled",
}

export const successAndPendingPaymentStatuses = [
  PaymentStatus.PENDING_APPROVAL,
  PaymentStatus.APPROVED,
  PaymentStatus.UNVERIFIED,
];

export const failedPaymentStatuses = [
  PaymentStatus.DECLINED,
  PaymentStatus.CANCELLED,
];

type Status = TransactionStatus | PaymentStatus;

export const isSuccessOrPendingPaymentRequest = (status: Status): boolean =>
  successAndPendingTransactionStatuses.includes(status as TransactionStatus) ||
  successAndPendingPaymentStatuses.includes(status as PaymentStatus);
