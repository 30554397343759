import React from "react";
import { ScrollView } from "react-native-gesture-handler";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { ProfileScreen } from "./Profile";
import ManageConsent from "./ManageConsent";


const SettingsScreen = (): React.ReactElement => {
    return (
        <ScrollView>
            <ScreenContainer>
                <ProfileScreen />
                <ManageConsent />
            </ScreenContainer>
        </ScrollView>
    );
}

export default SettingsScreen;