import React, { FunctionComponent } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import { useQueryClient } from "react-query";
import { QueryKey } from "../services/api/query";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { linkingConfiguration } from "./configs";
import { DASHBOARD_GRANTED_TIME } from "../constants/Common";
import { firebaseApp } from "../config/firebase";
import { getAuth, signOut } from "firebase/auth";
import {
  UnauthenticatedStack,
  BasiqConsentStack,
  OnboardingStack,
  AuthenticatedStack,
  ErrorStack,
} from "./base";

import type { User } from "../dtos/user";
import { RedirectErrorScreen } from "../components/molecules/RedirectToApp";
import { useActiveConnections } from "../hooks/useActiveConnections";
import { ActiveConnectionErrorScreen } from "../components/screens/Error/ActiveConnectionErrorScreen";
import { Spinner } from "../components/organisms/Spinner";

const RootStack = createStackNavigator<RootStackParamList>();

const renderRootWidgets = ({
  isAuthenticated,
  isDashboardBypassed,
  user,
}: RootNavigationProps) => {

  const toast = useToast();
  const queryClient = useQueryClient();
  const isRedirectApp = localStorage.getItem("isApp") || "";

  if (isRedirectApp === 'redirect') {
    return (
      <RootStack.Navigator screenOptions={{ headerShown: false }}>
        <RootStack.Screen name="RedirectErrorScreen" component={RedirectErrorScreen} />
      </RootStack.Navigator>
    )
  }

  if (!isAuthenticated) {
    return (
      <RootStack.Navigator screenOptions={{ headerShown: false }}>
        <RootStack.Screen
          name="Unauthenticated"
          component={UnauthenticatedStack}
        />
      </RootStack.Navigator>
    );
  }

  if (user == undefined || user == null) {
    toast.show("The Logged in user is invalid, Please try again", {
      type: "danger",
    });

    const auth = getAuth(firebaseApp);
    signOut(auth).then(async () => {
      queryClient.setQueryData(QueryKey.User, () => null);
      queryClient.setQueryData(QueryKey.Accounts, () => null);
      await AsyncStorage.removeItem(DASHBOARD_GRANTED_TIME);
    });

    return (
      <RootStack.Navigator screenOptions={{ headerShown: false }}>
        <RootStack.Screen
          name="Unauthenticated"
          component={UnauthenticatedStack}
        />
      </RootStack.Navigator>
    )
  }

  // Based on the onboardingComplete parameter, we handle the errors
  const isConsent = user?.isConsent;
  let onboardingComplete = user?.onboardingComplete ?? false;
  const isDeactivated = user?.isDeactivated || false;

  const isJobSucceeded =
    user?.basiqJobStatus &&
    user?.basiqJobStatus?.length > 0 &&
    user?.basiqJobStatus.filter((job) => job.status === "success").length ===
    user?.basiqJobStatus.length;



  if (onboardingComplete) {
    if (!isJobSucceeded && !isDashboardBypassed) {
      return (
        <RootStack.Navigator screenOptions={{ headerShown: false }}>
          <RootStack.Screen
            name="BasiqConsent"
            component={BasiqConsentStack}
            initialParams={{ ...user }}
          />
        </RootStack.Navigator>
      );
    }

    return <AuthenticatedStack name="Authenticated" />;
  } else {
    if (!isConsent && !isJobSucceeded) {
      if (isDeactivated && isDashboardBypassed) {

        return (
          <AuthenticatedStack name="Authenticated" />
        );
      }
      else {
        <RootStack.Navigator screenOptions={{ headerShown: false }}>
          <RootStack.Screen
            name="BasiqConsent"
            component={BasiqConsentStack}
            initialParams={{ ...user }}
          />
        </RootStack.Navigator>
      }

      return (
        <RootStack.Navigator screenOptions={{ headerShown: false }}>
          <RootStack.Screen
            name="BasiqConsent"
            component={BasiqConsentStack}
            initialParams={{ ...user }}
          />
        </RootStack.Navigator>
      );
    }
    // Consent wad deactivated but user has started or not finished re-activation
    if (isJobSucceeded || (isConsent && isDeactivated && isJobSucceeded)) {
      return (
        <RootStack.Navigator screenOptions={{ headerShown: false }}>
          <RootStack.Screen name="Onboarding" component={OnboardingStack} />
        </RootStack.Navigator>
      );
    }
  }
  if (!isJobSucceeded && isDeactivated && isDashboardBypassed && !onboardingComplete) {
    return <AuthenticatedStack name="Authenticated" />
  }

  return (
    <RootStack.Navigator screenOptions={{ headerShown: false }}>
      <RootStack.Screen name="Errors" component={ErrorStack} />
    </RootStack.Navigator>
  );
};

type RootNavigationProps = {
  isAuthenticated: boolean;
  isDashboardBypassed: boolean;
  user?: User;
};
export const RootNavigation: FunctionComponent<RootNavigationProps> = ({
  isAuthenticated,
  user,
  isDashboardBypassed,
}) => {
  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: "#ffffff",
        },
      }}
      linking={linkingConfiguration}
    >
      {renderRootWidgets({ isAuthenticated, isDashboardBypassed, user })}
    </NavigationContainer>
  );
};
