import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { getAuth } from "firebase/auth";
import { appConfig } from "../../config/config";
import { firebaseApp } from "../../config/firebase";

export enum ErrorCode {
  resourceNotFound = "resource-not-found",
  bankConnectionError = "bank-connection-error",
  invalidBankAccount = "invalid-bank-account",
  userAlreadyExists = "user-already-exists",
  internalServerError = "internal-server-error",
  validation = "invalid-payload",
  encryptionKeyError = "encryption-key-error",
  argumentNullOrUndefinedOrEmpty = "argument-null-or-undefined-or-empty",
  invalidEmail = "auth/invalid-email",
  weakPassword = "auth/weak-password",
  emailAlreadyInUse = "auth/email-already-in-use",
  invalidCredentials = "invalid-credentials",
}

interface ErrorResponseData {
  message: string;
  code: ErrorCode;
  details?: Array<{
    message: string;
    path: Array<string>;
    type: string;
    content: { label: string; key: string };
  }>;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface ApiErrorResponse extends AxiosResponse {
  data: ErrorResponseData;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface ApiError extends AxiosError<ApiErrorResponse> {
  response: ApiErrorResponse;
}

export const getErrorMessage = (error: ApiError & Error): string => {
  let message = "";
  if (isAxiosError(error)) {
    message = error.response?.data.message ?? error.message;
    if (error.response?.data.details) {
      message += ": ";
      error.response?.data.details.forEach(d => {
        message += `${d.message}, `;
      });
    }
  } else {
    message = error?.message;
  }
  return message ?? "Something went wrong, please contact support";
};

export const convertErrorCodeToErrorMessage = (
  errorCode?: ErrorCode,
): string => {
  switch (errorCode) {
    case ErrorCode.encryptionKeyError:
      return "We're having trouble connecting to your banking provider to access your accounts. Please try reconnecting.";
    case ErrorCode.invalidEmail:
      return "Email address is invalid.";
    case ErrorCode.weakPassword:
      return "Password must be 6 or more characters.";
    case ErrorCode.emailAlreadyInUse:
      return "There is already an account associated with this email address.";
    case ErrorCode.invalidCredentials:
      return "Invalid login credentials. Check your username and password before trying again.";
    default:
      return "It looks like we're having trouble processing your request.";
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isAxiosError = (e: any): boolean =>
  e != null && e.isAxiosError === true;

// Request interceptor to add bearer token to API calls
const requestInterceptor = async (config: AxiosRequestConfig) => {
  const auth = getAuth(firebaseApp);
  const firebaseUser = auth.currentUser;
  const bearerToken = await firebaseUser?.getIdToken();
  if (bearerToken) {
    // eslint-disable-next-line no-param-reassign
    ((config ?? {}).headers ?? {}).Authorization = `Bearer ${bearerToken}`;
  }
  return config;
};

class ApiClient {
  private axios;

  constructor() {
    this.axios = axios.create({
      baseURL: `${appConfig.baseUrl}/${appConfig.env}/api`,
    });
    this.axios.interceptors.request.use(requestInterceptor);
  }

  get = async <T = any>(path: string) => {
    return (await this.axios.get<T>(path)).data;
  };

  post = async <T = any>(path: string, data?: any) => {
    return (await this.axios.post<T>(path, data)).data;
  };

  put = async <T = any>(path: string, data?: any) => {
    return (await this.axios.put<T>(path, data)).data;
  };

  delete = async <T = any>(path: string, data?: any) => {
    return (await this.axios.delete<T>(path, data)).data;
  };
}

export default new ApiClient();
