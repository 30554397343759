export const errorCategoryMap: Record<string, string> = {
    'verify-credentials': 'Credentials Verification',
    'retrieve-accounts': 'Accounts Retrieval',
    'retrieve-transactions': 'Transactions Retrieval'
};

export const onBoardingJobStatus: { title: string, context: { status: string } }[] = [
    {
        title: 'Initiate bank connection',
        context: {
            status: 'waiting'
        }
    },
    {
        title: 'Verifying connection',
        context: {
            status: 'waiting'
        }
    },
    {
        title: 'Generating new consent',
        context: {
            status: 'waiting'
        }
    }
];

