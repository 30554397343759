import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { t } from "react-native-tailwindcss";
import Button from "../../../atoms/RoundedButtons";
import Card from "../../../atoms/Card";
import Currency from "../../../atoms/Currency";
import ClientLogo from "../../../atoms/ClientLogo";
import usePayments from "../../../../hooks/usePayments";
import { AppScreensParamList } from "../../../../navigation/app-screens";
import { HCenterStack, HStack } from "../../../layout/HStack";
import { MediumText } from "../../../atoms/MediumText";
import { LineTitle } from "../../../molecules/LineTitle";
import { useUser } from "../../../../context/userContext";
import { LargeText } from "../../../atoms/LargeText";

export const DonateNowComponenet = (): React.ReactElement => {
    const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
    const { totalCleared } = usePayments();
    const { user } = useUser();

    return (
        <Card style={styles.card}>
            <View>
                <MediumText center style={{ fontWeight: "600" }}>
                    One-Time Contribution
                </MediumText>
            </View>
            <HCenterStack style={[t.pT4]}>
                <View style={{ width: '70%' }}>
                    <Button
                        label="Contribute Now"
                        onPress={() => navigation.navigate("PayNow")}
                    />
                </View>
            </HCenterStack>
        </Card>
    );
};
const styles = {
    card: [t.flex, t.flexCol, t.p5, t.mB6, t.bgWhite, t.shadowXl],
};

export default DonateNowComponenet;
