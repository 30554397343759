import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { CriticalErrorScreen } from "../../components/screens/Error/CriticalError/CriticalErrorScreen";
import { HelpScreen } from "../../components/screens/Shared/Help/HelpScreen";
import { PrivacyPolicyScreen } from "../../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { TermsScreen } from "../../components/screens/Shared/Terms/TermsScreen";
import { RedirectErrorScreen } from "../../components/molecules/RedirectToApp";


const errorScreenList: IScreenRoute[] = [
  { name: "ErrorScreen", component: CriticalErrorScreen, options: { title: "Welcome" } },
  { name: "RedirectErrorScreen", component: RedirectErrorScreen, options: { title: "Welcome" } },
  { name: "Help", component: HelpScreen, options: { title: "Help" } },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    options: { title: "Privacy Policy" },
  },
  {
    name: "Terms",
    component: TermsScreen,
    options: { title: "Terms & Conditions" },
  },
];

const ErrorStackRoot = createStackNavigator<ErrorRootStackParamList>();

export const ErrorStack = () => (
  <ErrorStackRoot.Navigator
    initialRouteName="ErrorScreen"
    screenOptions={{ headerShown: false }}
  >

    {errorScreenList.map(({ name, component, options }, index) => (
      <ErrorStackRoot.Screen
        key={index}
        name={name as keyof ErrorRootStackParamList}
        component={component}
        options={options}
      />
    ))}
  </ErrorStackRoot.Navigator>
);
