import styled from "styled-components/native";

export const VStack = styled.View`
  display: flex;
  flex-direction: column;
`;

export const VCenterStack = styled(VStack)`
  justify-content: center;
`;
