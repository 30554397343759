import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

export const LinkingConfiguration: LinkingOptions = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Registration: {
        path: "Registration/:customerReference?/:contributionLimit?/:firstName?/:lastName?/:mobile?/:email?",
      },
      NotFound: "*",
    },
  },
};
