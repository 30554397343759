import styled from "styled-components/native";

export const HStack = styled.View`
  display: flex;
  flex-direction: row;
`;

export const HCenterStack = styled(HStack)`
  justify-content: center;
`;
