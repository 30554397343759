import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ConfirmationPopup, { ConfirmationPopupRef } from '../layout/ConfirmationPopup';
import { View, Text } from "react-native";


const RoundUpDisablePopUp: React.ForwardRefRenderFunction<ConfirmationPopupRef, { handleOnConfirm: () => void; }> = ({ handleOnConfirm }, ref) => {

    const ConfirmRefModal = useRef<ConfirmationPopupRef>(null);


    useImperativeHandle(ref, () => ({
        show: () => {
            ConfirmRefModal.current?.show();
        },
        hide: () => {
            ConfirmRefModal.current?.hide();
        },
    }));

    return (
        <ConfirmationPopup
            ref={ConfirmRefModal}
            sequentialLoader={{}}
            title={'Are you sure you want to Pause your Round-Ups ?'}
            message={
                <View>
                    <Text style={{ paddingTop: '6px' }}>{'Are you sure you would like to pause your Round-Ups ?'}</Text>
                </View>
            }
            onCancel={() => { }}
            onConfirm={handleOnConfirm}
            confirmButtonType={'success'}
            confirmButtonText={'Confirm'}
            isLoading={false}
            isDefaultLoader={false}
            hideCancelButton={false}
        />
    );
};

export default forwardRef(RoundUpDisablePopUp);

