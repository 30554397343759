import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { ToastProvider } from "react-native-toast-notifications";
import React, { useState, useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClientProvider } from "react-query";
// Related to v2 flow
import { Provider } from "react-redux";

import { appConfig } from "./config/config";
import useCachedResources from "./hooks/useCachedResources";
import { Navigation } from "./navigation/Navigation";
import { AppContainer } from "./components/layout/AppContainer";
import { queryClient } from "./services/api/query";
import { UserProvider, useUser } from "./context/userContext";
import { AccountsProvider, useAccounts } from "./context/accountsContext";
import { AuthProvider, useAuth } from "./context/authContext";
import { useError, ErrorProvider } from "./context/errorContext";
import { ClubsProvider } from "./context/clubContext";
import { Spinner } from "./components/organisms/Spinner";
import { firebaseApp } from "./config/firebase";
import "@fontsource/open-sans";

// Related to v2 flow
import store from "./v2/store";
import { RootScreen } from "./v2/Screens";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import { TenantProvider } from "./context/tenantContext";

if (__DEV__) {
  import("react-query-native-devtools").then(({ addPlugin }) => {
    addPlugin({ queryClient });
  });
}

const Screens = () => {
  const isLoadingCache = useCachedResources();
  const { user, isUserLoading } = useUser();
  const { accounts, isAccountsLoading } = useAccounts();
  const { authenticated } = useAuth();
  const { criticalError } = useError();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCriticalError, setIsCriticalError] = useState(false);

  // watch login state
  useEffect(() => {
    const fullyLoggedIn = !!authenticated && !!user && !!accounts;
    setIsLoggedIn(fullyLoggedIn);
  }, [authenticated, user, accounts]);

  // watch error state
  useEffect(() => {
    setIsCriticalError(!!criticalError);
  }, [criticalError]);

  if (!isLoadingCache || isUserLoading || isAccountsLoading) {
    return <Spinner />;
  }

  const isOnboardingComplete =
    (isLoggedIn && user?.onboardingComplete) ?? false;

  return (
    <SafeAreaProvider>
      <AppContainer>
        <StatusBar backgroundColor={appConfig.primaryColour} />
        <Navigation
          user={user}
          isAuthenticated={isLoggedIn}
          isCriticalError={isCriticalError}
          onboardingComplete={isOnboardingComplete}
        />
      </AppContainer>
    </SafeAreaProvider>
  );
};

// NOTE: ROOT OF THE APPLICATION
export default function App(): React.ReactElement | null {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ErrorProvider>
          <TenantProvider>
            <AuthProvider>
              <UserProvider>
                <AccountsProvider>
                  <ClubsProvider>
                    <ToastProvider offsetBottom={100}>
                      {/* LOOK UP */}
                      {/* Old one - Screens */}
                      {/* <Screens /> */}
                      <RootScreen />
                    </ToastProvider>
                  </ClubsProvider>
                </AccountsProvider>
              </UserProvider>
            </AuthProvider>
          </TenantProvider>
        </ErrorProvider>
      </QueryClientProvider>
    </Provider>
  );
}
