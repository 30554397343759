import React, { useEffect, useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import styled from "styled-components/native";
import {
  calculateAppDisplayWidthOnWeb,
  hasWidthAvailableForAppAndSidebar,
  requiresLeftAnchor,
  WEB_SIDEBAR_WIDTH,
} from "../../web-helper";
import { HStack, HCenterStack } from "./HStack";
import { VStack } from "./VStack";

type Props = {
  children: any;
};

const SidebarAlignedHStack = styled(HStack)`
  position: absolute;
  top: 0;
  left: ${WEB_SIDEBAR_WIDTH};
`;

const ContentContainer = styled.View<{
  platformSpecificWidth: string;
  platformSpecificHeight: string;
}>`
  display: flex;
  flex-direction: column;
  height: ${props => props.platformSpecificHeight};
  width: ${props => props.platformSpecificWidth};
`;

export const AppContainer = (props: Props): React.ReactElement => {
  const { width, height } = useWindowDimensions();
  const [availableWidth, setAvailableWidth] = useState(width);

  useEffect(() => {
    setAvailableWidth(width);
  }, [width]);

  const platformSpecificWidth =
    Platform.select({
      web: `${calculateAppDisplayWidthOnWeb(availableWidth)}px`,
    }) ?? `${width}px`;

  const platformSpecificHeight = `${height}px`;

  const WebCompatibleHStack =
    Platform.select({
      // here be magic...
      // long story short, accounting for the fact that react navigation drawer navigators float
      // off screen to the left by design makes calculating available space hard.
      // eslint-disable-next-line no-nested-ternary
      web: hasWidthAvailableForAppAndSidebar(availableWidth)
        ? requiresLeftAnchor(availableWidth)
          ? SidebarAlignedHStack
          : HCenterStack
        : HCenterStack,
    }) ?? HCenterStack;

  return (
    <VStack>
      <WebCompatibleHStack>
        <ContentContainer
          platformSpecificWidth={platformSpecificWidth}
          platformSpecificHeight={platformSpecificHeight}
        >
          {props.children}
        </ContentContainer>
      </WebCompatibleHStack>
    </VStack>
  );
};
