import React, { FunctionComponent, useEffect, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";

import useCachedResources from "../hooks/useCachedResources";

import { Spinner } from "../components/organisms/Spinner";
import { getUserToken } from "./configs";
import { useGetUserQuery, useGetAccountQuery } from "./slices";
import { AppContainer } from "../components/layout/AppContainer";
import { appConfig } from "../config/config";
import { RootNavigation } from "./RootNavigation";
import { DASHBOARD_GRANTED_TIME } from "../constants/Common";
import { firebaseApp } from "../config/firebase";
import { ApiError } from "../services/api/api";
import jwt_decode from "jwt-decode";
import {
  getAuth,
  User as FirebaseUser,
  signInWithCustomToken,
  updateEmail,
  signOut,
  Auth
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useUser } from "../context/userContext";
import { saveLoginData, retrieveLoginData, FormData } from '../components/screens/Auth/Login/LoginLocalStorageHandler';
import { userLoginType } from '../dtos/user';
import { useToast } from "react-native-toast-notifications";
import utils from "../utils";
import { QueryKey, queryClient } from "../services/api/query";

type RootScreenProps = {};
export const RootScreen: FunctionComponent<RootScreenProps> = () => {
  const [isAuthenticated, setAuthenticated] = useState<boolean | null>(null);
  const [isDashboardBypassed, setDashboardBypassed] = useState<boolean | null>(null);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const [isTokenLoginFailed, setisTokenLoginFailed] = useState<boolean>(false);
  const toast = useToast();


  const initiateToken = async () => {
    const token = await getUserToken();

    setAuthenticated(token !== null);
  };

  const checkDashboardBypassSession = async () => {
    try {
      const value = await AsyncStorage.getItem(DASHBOARD_GRANTED_TIME);
      const timestampString = value !== null ? value : "";

      const hours = moment().diff(moment(timestampString), "hours");
      setDashboardBypassed(hours <= 1);
    } catch (e) {
      setDashboardBypassed(false);
    }
  };

  const loginUserByToken = async () => {
    const searchParams = new URLSearchParams(document.location.search);
    const firebaseToken = searchParams.get('token');
    const isApp = searchParams.get('isApp') || "";
    if (firebaseToken !== null &&
      firebaseToken !== "") {
      try {
        setIsLoginLoading(true);
        const auth = getAuth(firebaseApp);
        await checkFirebaseAuthAndSignOut(auth);
        await signInWithCustomToken(auth, firebaseToken)
          .then((userCredential) => {
            userCredential.user.getIdToken()
              .then((idToken) => {
                const decode_token = jwt_decode<jwtToken>(idToken);
                console.log({ decode_token });
                if (auth.currentUser && decode_token.email) {
                  if (!isLoggiedInAlready()) {
                    const loginData = {
                      email: "",
                      password: "",
                      isRememberMe: false,
                      loginType: userLoginType.sso,
                      firebaseToken: idToken,
                      isLoggedIn: true
                    }
                    saveLoginData(loginData);
                    setIsAppStorageKeyAndRedirect(isApp);
                    window.location.reload();
                  }
                }
              })
              .catch((error: ApiError & Error & FirebaseError & any) => {
                console.log(error.message);
                setisTokenLoginFailed(true);
              });
          })
          .catch((error: ApiError & Error & FirebaseError & any) => {
            console.log(error.message);
            setisTokenLoginFailed(true);
          });
      } catch (error: ApiError & Error & FirebaseError & any) {
        console.log(error.message);
        setisTokenLoginFailed(true);
      } finally {
        setIsLoginLoading(false);
        setIsAppStorageKeyAndRedirect(isApp);
      }

    }
  }

  const isLoggiedInAlready = (): boolean => {
    const loginData: FormData = retrieveLoginData();
    if (loginData &&
      (
        loginData.loginType &&
        loginData.loginType === userLoginType.sso &&
        loginData.isLoggedIn
      )) {
      return true;
    } else {
      return false;
    }
  }

  const checkFirebaseAuthAndSignOut = async (auth: Auth) => {
    const loginData: FormData = retrieveLoginData();
    console.log("firebaseUser - ", loginData?.isLoggedIn);
    if (loginData && loginData?.isLoggedIn) {
      await signOut(auth).then(async () => {
        queryClient.setQueryData(QueryKey.User, () => null);
        queryClient.setQueryData(QueryKey.Accounts, () => null);
      });
    } else {
      queryClient.setQueryData(QueryKey.User, () => null);
      queryClient.setQueryData(QueryKey.Accounts, () => null);
    }
  }

  const setIsAppStorageKeyAndRedirect = (isApp: String) => {
    let curreentKey = localStorage.getItem('isApp');
    if (isApp === "true") {
      localStorage.setItem("isApp", "true");
    }
    else if (curreentKey === "redirect" || (curreentKey === 'true' && !isApp)) {
      localStorage.setItem("isApp", "");
      window.location.reload();
    }
  }

  const initilizeDataForPage = async () => {
    await loginUserByToken();
    initiateToken();
    checkDashboardBypassSession();
  }

  useEffect(() => {
    initilizeDataForPage();
  }, []);

  const { data: userData, isLoading: isUserLoading } = useGetUserQuery(
    undefined,
    {
      skip: !isAuthenticated,
    }
  );

  const isLoadingCacheComplete = useCachedResources();

  if (
    isAuthenticated === null ||
    isDashboardBypassed === null ||
    !isLoadingCacheComplete ||
    isUserLoading ||
    isLoginLoading
  ) {
    return <Spinner />;
  }

  if (isTokenLoginFailed) {
    toast.show("Login token is invalid, please try again with a valid token", {
      type: "danger",
      duration: 4000,
    });
  }

  return (
    <SafeAreaProvider>
      <AppContainer>
        <StatusBar backgroundColor={appConfig.primaryColour} />
        <RootNavigation
          isAuthenticated={isAuthenticated}
          isDashboardBypassed={isDashboardBypassed}
          user={userData}
        />
      </AppContainer>
    </SafeAreaProvider>
  );
};

interface jwtToken {
  email: string;
}
