import React from "react";
import styled from "styled-components/native";
import { appConfig } from "../../config/config";

const StyledTouchableOpacity = styled.TouchableOpacity<
  Pick<AppButtonProps, "colour">
>`
  padding-horizontal: 16px;
  padding-vertical: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.colour};
  width: 100%;
  height: '48px';
`;

const StyledText = styled.Text`
  color: #ffffff;
  text-align: center;
  font-weight: 500;
`;

const disabledColour = "#d6d6d6"

export type AppButtonProps = {
  label: string;
  colour?: string;
  disabled?: boolean;
  onPress: () => any;
};

export default function Button({
  label,
  colour = appConfig.primaryColour,
  disabled = false,
  ...props
}: AppButtonProps): React.ReactElement {
  return (
    <StyledTouchableOpacity
      disabled={disabled}
      activeOpacity={0.8}
      colour={disabled ? disabledColour : colour}
      {...props}
    >
      <StyledText>{label}</StyledText>
    </StyledTouchableOpacity>
  );
}
