import {
  DrawerContentComponentProps,
  DrawerContentOptions,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import { getAuth, signOut } from "firebase/auth";
import * as React from "react";
import { SafeAreaView, StyleProp, TextStyle } from "react-native";
import { t } from "react-native-tailwindcss";
import { useQueryClient } from "react-query";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { firebaseApp } from "../config/firebase";
import { useError } from "../context/errorContext";
import { QueryKey } from "../services/api/query";
import { AppNavigationScreen } from "./app-screens";
import { DASHBOARD_GRANTED_TIME } from "../constants/Common";
import { retrieveLoginData, FormData, saveLoginData } from '../components/screens/Auth/Login/LoginLocalStorageHandler';
import { userLoginType } from '../dtos/user';
import jwt_decode from "jwt-decode";

interface Props extends DrawerContentComponentProps<DrawerContentOptions> {
  items: AppNavigationScreen[] | IScreenRoute[];
}
export const DrawerWithLogoutButton = (props: Props): React.ReactElement => {
  const { index, routes } = props.navigation.getState();
  const queryClient = useQueryClient();
  const { setCriticalError } = useError();
  const currentRouteName = routes[index].name;

  const logout = () => {
    const loginData: FormData = retrieveLoginData();

    setCriticalError(null);
    const auth = getAuth(firebaseApp);
    signOut(auth).then(async () => {
      queryClient.setQueryData(QueryKey.User, () => null);
      queryClient.setQueryData(QueryKey.Accounts, () => null);
      // TODO: Must remove Dashboard bypass key
      await AsyncStorage.removeItem(DASHBOARD_GRANTED_TIME);
      // Used to reload

      if (loginData &&
        (
          loginData.loginType &&
          loginData.loginType === userLoginType.sso
        )) {
        getRedirectUrlFromToken(loginData);
      }
      else {
        window.location.reload();
      }
    });
  };

  const getRedirectUrlFromToken = (loginData: FormData) => {
    // The logout redirection logic can be introduced in the future
    // So that this method has been created seperately to handle that
    const loginDataForSave = {
      email: "",
      password: "",
      isRememberMe: false,
      loginType: userLoginType.emailPass,
      firebaseToken: "",
      isLoggedIn: false,
    }
    saveLoginData(loginDataForSave)
    window.location.reload();
  }

  const getLabelStyle = (focused: boolean): StyleProp<TextStyle> => {
    const style: StyleProp<TextStyle> = { color: "black" };
    if (focused) {
      style.textDecorationLine = "underline";
    }
    return style;
  };

  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={[t.flex1, { marginTop: 56 }, t.mL3]}
    >
      <SafeAreaView>
        {(props.items as IScreenRoute[]).map((item) => {
          const options = (item.options as Function)();
          const { title } = options;

          return (
            <DrawerItem
              key={item.name}
              label={title}
              onPress={() => props.navigation.navigate(item.name)}
              focused={currentRouteName === item.name}
              labelStyle={getLabelStyle(currentRouteName === item.name)}
              activeTintColor="#FFFFFF"
            />
          );
        })}
        <DrawerItem
          label="Logout"
          onPress={logout}
          labelStyle={{ color: "black" }}
        />
      </SafeAreaView>
    </DrawerContentScrollView>
  );
};

interface jwtToken {
  redirectUrl: string;
}