import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import { retrieveLoginData, FormData } from '../../components/screens/Auth/Login/LoginLocalStorageHandler';
import { userLoginType } from '../../dtos/user';

import { firebaseApp } from "../../config/firebase";

export const getUserToken = () =>
  new Promise((resolve) => {
    onAuthStateChanged(getAuth(firebaseApp), async (user) => {
      const loginData: FormData = retrieveLoginData();
      if (loginData &&
        (
          loginData.loginType &&
          loginData.loginType === userLoginType.sso
        )) {

        if (user) {
          const token = await getIdToken(user);

          resolve(token);
          return;
        }
        resolve(null);
      } else {
        if (user && user.emailVerified) {
          const token = await getIdToken(user);

          resolve(token);
          return;
        }
        resolve(null);
      }
    });
  });
