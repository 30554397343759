import React, { useEffect, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { t } from "react-native-tailwindcss";
import { useMutation, useQueryClient } from "react-query";
import { QueryKey } from "../../../../services/api/query";
import Button from "../../../atoms/Button";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { MediumText } from "../../../atoms/MediumText";
import { HCenterStack } from "../../../layout/HStack";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { Footer } from "../../../molecules/Footer";
import { useError } from "../../../../context/errorContext";
import ClientLogo from "../../../atoms/ClientLogo";
import {
  convertErrorCodeToErrorMessage,
  ErrorCode,
} from "../../../../services/api/api";
import { useUser } from "../../../../context/userContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DASHBOARD_GRANTED_TIME, IJobFeedback } from "../../../../constants/Common";
import { generateNewConsentLink, getActiveConnections } from "../../../../services/bankService";
import { Spinner } from "../../../organisms/Spinner";
import { JobFeedback } from "../../../organisms/JobFeedback";


const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

export const CriticalErrorScreen = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const { user, isUserLoading } = useUser();
  const { criticalError, setCriticalError } = useError();
  const errorMessage = convertErrorCodeToErrorMessage(
    criticalError?.response?.data?.code,
  );
  const buttonText =
    criticalError?.response?.data?.code === ErrorCode.encryptionKeyError
      ? "Reconnect"
      : "Reload";

  useEffect(() => {
    generateScreenContent(user?.basiqJobStatus);
  }, [user]);


  const [isLoading, setIsLoading] = useState(false);
  const [errorScreenContents, setErrorScreenContents] = useState({
    heading: '',
    action: errorMessage,
    status: '',
  });
  const [userConsentURL, setUserConsentURL] = useState<string>('');

  const consentLinkSteps: IJobFeedback = {
    steps: [
      {
        title: 'Creating new consent link',
        context: {
          status: 'pending',
        }
      },
    ],
    description: "",
  }



  let [loaderSteps, setLoaderSteps]: any = useState<IJobFeedback | null>(consentLinkSteps);

  const { mutate: fetchActiveConnections } = useMutation(getActiveConnections, {
    onSuccess: (data) => {
      if (data?.length) {
        window.location.reload();
      }
      else
        setIsLoading(false);
    },
    onError: (error) => setIsLoading(false)
  });

  const { mutate: generateNewConsent, data: ConsentLinkStatus, isLoading: isNewConsentLoading } = useMutation(generateNewConsentLink, {
    onError: (error: any) => { },
    onSuccess: (data: any) => {
      if (data?._id && data?.consent) {
        setUserConsentURL(data?.consent);
      }
    }
  });


  const generateScreenContent = (jobStatus: any) => {
    if (jobStatus?.length > 0) {
      let errorStep = jobStatus.find((step: any) => {
        if (step.isUserActionRequired || step.status !== "success") {
          return step;
        }
      });
      try {
        switch (errorStep?.title) {
          case "verify-credentials":
          case "retrieve-transactions":
          case "retrieve-accounts":
            if (errorStep?.status === "failed") {
              generateNewConsent('manage')
              if (errorStep?.action) {
                setErrorScreenContents({
                  heading: "Something went wrong",
                  action: errorStep?.action || '',
                  status: errorStep?.status || 'failed',
                })
              }
              else {
                setDefaultMessage();
              }
              break;
            }
            else {
              setErrorScreenContents({
                heading: "Fetching Account details",
                action: 'We are still trying to retrieve your transaction details. Please wait for some time and try refreshing the connection.',
                status: 'pending',
              })
              break;
            }
          default:
            setDefaultMessage();
        }
      }
      catch {
        setDefaultMessage();
      }
    }
  }
  const setDefaultMessage = () => {
    setErrorScreenContents({
      heading: "Something went wrong",
      action: errorMessage || '',
      status: 'failed',
    });
  }

  if (!user || isUserLoading) {
    return <Spinner />;
  }

  return (
    <ScreenContainer center>
      {isLoading ?
        <View style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <Spinner />
        </View>
        : null
      }
      <HCenterStack>
        <View style={{ height: 120, width: "100%", paddingVertical: 10 }}>
          <ClientLogo />
        </View>
      </HCenterStack>
      <HCenterStack>
        <ExtraLargeText center>{errorScreenContents.heading || ''}</ExtraLargeText>
      </HCenterStack>

      <HCenterStack>
        <MediumText>{errorScreenContents.action || ''}</MediumText>
      </HCenterStack>
      {user?.isDeactivated ?
        null
        :
        <HCenterStack>
          <MediumText>Please, reload the page or logout and login the user again.</MediumText>
        </HCenterStack>}

      <View style={styles.buttonContainer}>
        <Button
          onPress={() => {
            // refetch data

            // clear error
            setCriticalError(null);
            if (errorScreenContents?.status !== 'failed') {
              fetchActiveConnections();
              setIsLoading(true);
            }
            else {
              queryClient.invalidateQueries(QueryKey.User);
              queryClient.invalidateQueries(QueryKey.Accounts);
              window.location.reload();
            }
          }}
          colour="grey"
          label={errorScreenContents?.status !== 'failed' ? 'Refresh Connection' : buttonText}
          disabled={isLoading}
        />
        {errorScreenContents && errorScreenContents?.status === 'failed' ?
          <View>
            <View style={{ padding: 8 }}></View>
            {isNewConsentLoading ?
              <View><JobFeedback steps={loaderSteps?.steps} description={loaderSteps?.description || ''} /></View>
              :
              <Button
                label={'Reconsent'}
                onPress={async () => {
                  window.open(userConsentURL, "_self");
                }}
              />
            }
          </View>
          : null}
        <View style={{ padding: 8 }}></View>
        {user?.isDeactivated ?
          <Button
            label={'Continue to Dashboard'}
            colour="grey"
            disabled={isLoading}
            onPress={async () => {
              try {
                await AsyncStorage.setItem(
                  DASHBOARD_GRANTED_TIME,
                  `${new Date().toISOString()}`
                );
                window.location.reload();
              } catch (e) {
                // saving error
              }
            }}
          />
          : null}
      </View>

      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200, marginTop: 20 }, t.mLAuto, t.mRAuto],
};
