import React, { useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { t } from "react-native-tailwindcss";
import { ExtraLargeText } from "../atoms/ExtraLargeText";
import { MediumText } from "../atoms/MediumText";
import { HCenterStack } from "../layout/HStack";
import { ScreenContainer } from "../layout/ScreenContainer";
import { Footer } from "./Footer";
import ClientLogo from "../atoms/ClientLogo";
import { retrieveLoginData, FormData } from '../../components/screens/Auth/Login/LoginLocalStorageHandler';

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

export const RedirectErrorScreen = (): React.ReactElement => {

  useEffect(() => {
    try {
      const loginData: FormData = retrieveLoginData();
      if (loginData.isLoggedIn) {
        // @ts-ignore
        window.Print.postMessage("rounda-success");
      } else {
        // @ts-ignore
        window.Print.postMessage("rounda-logout");
      }
    }
    catch (e) {
      console.log('Error in postmessage ', e);
      // @ts-ignore
      window?.Print?.postMessage("rounda-logout");
    }
  }, []);

  return (
    <ScreenContainer center style={{ height: "100%", justifyContent: 'flex-start' }}>
      <HCenterStack>
        <View style={{ height: 140, width: "100%", paddingVertical: 20 }}>
          <ClientLogo />
        </View>
      </HCenterStack>
      <HCenterStack>
        <ExtraLargeText center>Thank you for using our app!</ExtraLargeText>
      </HCenterStack>
      <HCenterStack>
        <MediumText>In order to continue securely, please close the browser. If it doesn't close automatically, you can do it manually. We appreciate your cooperation!</MediumText>
      </HCenterStack>
      <StyledFooterContainer>
        <Footer hideLogOut={true} />
      </StyledFooterContainer>
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200, marginTop: 20 }, t.mLAuto, t.mRAuto],
};
