import { appScreens } from "./app-screens";
import { authScreens } from "./auth-screens";
import { setupScreens } from "./setup-screens";

export type Options = {
  title?: string;
  headerShown?: boolean;
  headerLeft?: () => JSX.Element;
};

export type NavigationScreen = {
  name: string;
  component: (...args: any[]) => React.ReactElement;
  options: (navigation?: any) => Options;
};

export const getScreens = (): NavigationScreen[] =>
  (appScreens as NavigationScreen[]).concat(authScreens).concat(setupScreens);
