import { NavigationProp, useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { t } from "react-native-tailwindcss";
import { ScrollView } from "react-native-gesture-handler";
import Card from "../../../atoms/Card";
import Currency from "../../../atoms/Currency";
import usePayments from "../../../../hooks/usePayments";
import { AppScreensParamList } from "../../../../navigation/app-screens";
import { MediumText } from "../../../atoms/MediumText";
import { TransactionStatus } from "../../../../dtos/payment";

interface MonthlyTotal {
  label: string;
  value: number;
}

const MonthlyTotalRow = ({ monthlyTotal }: { monthlyTotal: MonthlyTotal }) => (
  <View style={styles.totalRow}>
    <MediumText>{monthlyTotal.label}</MediumText>
    <MediumText>
      <Currency cents={monthlyTotal.value} />
    </MediumText>
  </View>
);

export const MonthlyPayments = (): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const { payments } = usePayments();
  const [monthlyTotals, setMonthlyTotals] = useState<MonthlyTotal[]>([]);

  useEffect(() => {
    const filteredPayments = payments.filter(
      (p) =>
        TransactionStatus.CLEARED ||
        TransactionStatus.MATURED ||
        TransactionStatus.PROCESSING ||
        TransactionStatus.CLEARING
    );
    // console.log({ filteredPayments });
    const monthToAmount = filteredPayments.reduce(
      (acc: Record<string, any>, payment) => {
        const parsedDate = new Date(payment._createdDate);
        const monthYear = `${parsedDate.getMonth()}-${parsedDate.getFullYear()}`;
        const totalMonth = acc[monthYear]?.price ?? 0;
        // console.log({ amount: payment });
        return {
          ...acc,
          [monthYear]: {
            price: totalMonth + payment.totalDebitAmount ?? 0,
            label: format(parsedDate, "MMMM yyyy"),
          },
        };
      },
      {}
    );

    const m = Object.entries(monthToAmount)
      .sort(([aKey], [bKey]) => {
        return `${aKey}`.localeCompare(bKey);
      })
      .map(([, value]) => {
        return {
          label: value.label,
          value: value.price as number,
        };
      });

    setMonthlyTotals(m);
  }, [payments]);

  const viewTransactions = (): void => {
    navigation.navigate("Transactions");
  };

  return (
    <Card style={styles.card}>
      <View style={styles.headingContainer}>
        <MediumText style={{ fontWeight: "600" }}>Contribution History</MediumText>
        <Text style={styles.viewText} onPress={viewTransactions}>
          View All
        </Text>
      </View>
      <ScrollView>
        {monthlyTotals.map((mt, i) => (
          <MonthlyTotalRow key={i.toString()} monthlyTotal={mt} />
        ))}
      </ScrollView>
    </Card>
  );
};

const styles = {
  card: [t.flex, t.p5, t.flexCol, t.bgWhite, t.shadowXl],
  totalRow: [t.flex, t.flexRow, t.justifyBetween],
  headingContainer: [t.flex, t.flexRow, t.justifyBetween],
  viewText: [{ color: "#1D8ECF" }],
};
