import React, { ReactNode, useContext } from "react";
import { useQuery } from "react-query";
import { useError } from "../context/errorContext";
import { ApiError } from "../services/api/api";
import { QueryKey } from "../services/api/query";
import { BankAccount, getAccounts } from "../services/bankService";

type AccountsContext = {
  accounts?: BankAccount[] | null;
  error?: ApiError | null;
  isAccountsLoading: boolean;
};

const AccountsContext = React.createContext<AccountsContext>(
  {} as AccountsContext
);

const AccountsProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const { setCriticalError } = useError();
  const useAccountsQuery = useQuery<BankAccount[] | null, ApiError>(
    [QueryKey.Accounts],
    () => getAccounts(),
    {
      onError: (e) => setCriticalError(e),
    }
  );
  return (
    <AccountsContext.Provider
      value={{
        accounts: useAccountsQuery.data,
        error: useAccountsQuery.error,
        isAccountsLoading: useAccountsQuery.isFetching,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

const useAccounts = (): AccountsContext => useContext(AccountsContext);
export { AccountsProvider, useAccounts };
