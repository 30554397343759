import React, { FunctionComponent, useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  View,
  StyleSheet,
  Text,
} from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Job, JobStatus } from "../../../../../dtos/job";
import { User } from "../../../../../dtos/user";
import { ApiError } from "../../../../../services/api/api";
import { QueryKey } from "../../../../../services/api/query";
import { getJob } from "../../../../../services/jobService";
import {
  getUser,
  refreshConnection,
  updateConsent,
} from "../../../../../services/userService";
import Button, { AppButtonProps } from "../../../../atoms/Button";
import { HCenterStack } from "../../../../layout/HStack";
import { ScreenContainer } from "../../../../layout/ScreenContainer";
import { Footer } from "../../../../molecules/Footer";
import { OnboardingMessage } from "../components/OnboardingMessage";
import { appConfig } from "../../../../../config/config";
import { DASHBOARD_GRANTED_TIME, IJobFeedback } from "../../../../../constants/Common";
import { MediumText } from "../../../../atoms/MediumText";
import { Spinner } from "../../../../organisms/Spinner";
import { generateNewConsentLink } from "../../../../../services/bankService";
import { JobFeedback } from "../../../../organisms/JobFeedback";
import { authStyles } from "../../../Auth/shared";
import ClientLogo from "../../../../atoms/ClientLogo";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

const ActivateConsentSteps: IJobFeedback = {
  steps: [
    {
      title: 'Creating new consent link',
      context: {
        status: 'pending',
      }
    },
  ],
  description: "We are creating a new consent link.",
}

type OnboardCompletedFailureProps = {};
export const OnboardCompletedFailure: FunctionComponent<
  OnboardCompletedFailureProps
> = () => {
  const queryClient = useQueryClient();
  const [reconsentRunning, setReconsentRunningState] = useState<boolean>(false);
  const [loaderSteps, setLoaderSteps]: any = useState(ActivateConsentSteps);
  const { data: updateConsentData, refetch: requestToUpdateConsent } = useQuery<
    Job | null,
    ApiError
  >({
    enabled: false,
    refetchOnWindowFocus: false,
    queryKey: [QueryKey.UpdateConsent],
    queryFn: () => updateConsent(),
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.RefreshBasiqConnection);
    },
  });

  const {
    data: refreshConnectionData,
    refetch: requestToRefreshConnectionData,
  } = useQuery<Job | null, ApiError>({
    enabled: false,
    refetchOnWindowFocus: false,
    queryKey: [QueryKey.RefreshBasiqConnection],
    queryFn: () => refreshConnection(),
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.UpdateConsent);
    },
  });

  useEffect(() => {
    generateConsent();
  }, [])


  const { mutate: generateConsent, data: ConsentLinkStatus } = useMutation(generateNewConsentLink, {
    onError: (error: any) => { },
    onSuccess: (data: any) => {
      if (ConsentLinkStatus?._id) {
        let temp: any = { ...ActivateConsentSteps };
        temp.steps[0].context.status = 'pending';
        setLoaderSteps(temp);
      }
    }
  });

  // const { data: jobStatusData } = useQuery<Job | null, ApiError>({
  //   queryKey: [QueryKey.Jobs],
  //   queryFn: () =>
  //     getJob(
  //       ConsentLinkStatus?._id ? ConsentLinkStatus?._id : updateConsentData ? updateConsentData._id : refreshConnectionData?._id
  //     ),
  //   enabled: !!updateConsentData || !!refreshConnectionData || !!ConsentLinkStatus?._id,
  //   refetchInterval: (data) => {
  //     return data && data?.status === JobStatus.Pending ? 1000 : false;
  //   },
  // });

  const { data: user, isLoading: isUserLoading } = useQuery<
    User | null,
    ApiError
  >({
    queryKey: [QueryKey.User],
    queryFn: () => getUser(),
    enabled: !!ConsentLinkStatus,
    onSuccess: (data: any) => {
      if (ConsentLinkStatus?._id) {
        let temp: any = { ...ActivateConsentSteps };
        temp.steps[0].context.status = 'success';
        setLoaderSteps(temp);
      }
    }
  });

  useEffect(() => {
    if (ConsentLinkStatus) {
      setReconsentRunningState(false);
    }
  }, [ConsentLinkStatus]);

  if (isUserLoading) {
    return <Spinner />;
  }

  return user ? (
    <ScreenContainer center style={{ height: '100%' }}>
      <HCenterStack style={authStyles.imageContainer}>
        <View style={{ width: "70%" }}>
          <ClientLogo />
        </View>
      </HCenterStack>
      <OnboardingMessage user={user} />
      {((user && !user.consent) || reconsentRunning) && (
        <HCenterStack>
          <ActivityIndicator size="large" color={appConfig.primaryColour} />
        </HCenterStack>
      )}

      <View style={rnStyles.listContainerSeparator} />
      <JobFeedback
        steps={loaderSteps?.steps}
        description={loaderSteps?.description || ''}
      />
      <View style={rnStyles.listContainerSeparator} />
      <Button
        label="Reconsent"
        colour={reconsentRunning ? "grey" : appConfig.primaryColour}
        onPress={() => {
          if (user && !reconsentRunning) {
            window.open(user.consent, "_self");
          }
        }}
        disabled={loaderSteps?.steps && loaderSteps?.steps[0].context.status === 'pending'}
      />

      <View style={rnStyles.listContainerSeparator} />

      <ButtonListByUser
        user={user}
        setSpinner={setReconsentRunningState || loaderSteps?.steps && loaderSteps?.steps[0].context.status === 'pending'}
        isSpinnerRunning={reconsentRunning}
        requestToUpdateConsent={requestToUpdateConsent}
        requestToRefreshConnectionData={requestToRefreshConnectionData}
      />
      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScreenContainer>
  ) : (
    <></>
  );
};

enum ButtonLabels {
  updateConsent = "Update Consent",
  refreshConnection = "Refresh Connection",
  continueToDashboard = "Continue to Dashboard",
}

type ButtonMeta = {
  isDescriptionLink?: boolean;
  disabled?: boolean,
};
type ButtonListByUserProps = {
  user?: User | null;
  isSpinnerRunning?: boolean;
  requestToUpdateConsent: Function;
  requestToRefreshConnectionData: Function;
  setSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: true
};
const ButtonListByUser: FunctionComponent<ButtonListByUserProps> = ({
  user,
  setSpinner,
  isSpinnerRunning,
  requestToUpdateConsent,
  requestToRefreshConnectionData,
  disabled = false
}) => {
  if (user) {
    let buttonList: (AppButtonProps & ButtonMeta)[] = [];
    const { basiqJobStatus } = user;

    const jobStatusList = basiqJobStatus ?? [];

    const failedJobs: IBasiqJobStatus[] = jobStatusList.filter(
      ({ status }) => status === "failed"
    );

    const failedJobActionMapList: Array<{
      title: string;
      isUserActionRequired: boolean;
    }> = failedJobs.map(({ title, isUserActionRequired }) => ({
      title,
      isUserActionRequired,
    }));

    for (let i = 0; i < failedJobActionMapList.length; i++) {
      const { title, isUserActionRequired } = failedJobActionMapList[i];
      if (title === "verify-credentials") {
        const listIfActionRequired: AppButtonProps[] = isUserActionRequired
          ? [
            {
              label: ButtonLabels.updateConsent,
              onPress: () => {
                if (!isSpinnerRunning) {
                  setSpinner(true);
                  requestToUpdateConsent();
                }
              },
            },
            {
              label: ButtonLabels.refreshConnection,
              onPress: () => {
                if (!isSpinnerRunning) {
                  setSpinner(true);
                  requestToRefreshConnectionData();
                }
              },
            },
          ]
          : [
            {
              label: ButtonLabels.refreshConnection,
              onPress: () => {
                if (!isSpinnerRunning) {
                  setSpinner(true);
                  requestToRefreshConnectionData();
                }
              },
            },
          ];
        buttonList = [...buttonList, ...listIfActionRequired];
      }

      if (title === "retrieve-accounts" || title === "retrieve-transactions") {
        buttonList = [
          ...buttonList,
          {
            label: ButtonLabels.refreshConnection,
            onPress: () => {
              if (!isSpinnerRunning) {
                setSpinner(true);
                requestToRefreshConnectionData();
              }
            },
          } as AppButtonProps,
        ];
      }
    }

    buttonList = [
      ...buttonList,
      {
        label: ButtonLabels.continueToDashboard,
        colour: "grey",
        onPress: async () => {
          if (!isSpinnerRunning) {
            try {
              await AsyncStorage.setItem(
                DASHBOARD_GRANTED_TIME,
                `${new Date().toISOString()}`
              );
              window.location.reload();
            } catch (e) {
              // saving error
            }
          }
        },
      },
    ];

    const updatedList = buttonList.filter(
      (btn, index) =>
        buttonList.findIndex((fBtn) => btn.label === fBtn.label) === index
    );

    return (
      <FlatList
        data={updatedList}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={rnStyles.listContentContainer}
        ItemSeparatorComponent={() => <View style={rnStyles.buttonSeparator} />}
        renderItem={({ item, index }) => {
          const { ...props } = item;
          // return <Button {...props} />;
          return props.label === ButtonLabels.continueToDashboard ? (
            <Button {...props} />
          ) : (
            <MediumText center>
              Note {index + 1}: If your{" "}
              {props.label === ButtonLabels.refreshConnection
                ? "consent expires"
                : "consent fails"}{" "}
              then click{" "}
              <Text style={rnStyles.linkButton} onPress={props.onPress}>
                here
              </Text>
            </MediumText>
          );
        }}
        keyExtractor={(item, index) => `${item.label}-${index}`}
      />
    );
  }
  return <></>;
};

const rnStyles = StyleSheet.create({
  listContentContainer: {
    paddingTop: 10,
  },
  linkButton: {
    fontWeight: "700",
    textDecorationLine: "underline",
  },
  buttonSeparator: {
    height: 5,
  },
  reconsentButton: {
    height: 5,
  },
  listContainerSeparator: {
    height: 15,
  },
});
