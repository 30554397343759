import React, { ReactNode, useContext } from "react";
import { useQuery } from "react-query";
import { useError } from "./errorContext";
import { ApiError } from "../services/api/api";
import { QueryKey } from "../services/api/query";
import { Tenant, getTenantDetails } from "../services/tenantService";

type TenantContext = {
  tenant?: Tenant | null;
  error?: ApiError | null;
  isTenantLoading: boolean;
};

const TenantContext = React.createContext<TenantContext>(
  {} as TenantContext
);

const TenantProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {

  const { setCriticalError } = useError();
  const useTenantQuery = useQuery<Tenant | null, ApiError>(
    [QueryKey.Tenant],
    () => getTenantDetails(),
    {
      onError: (e) => setCriticalError(e),
    }
  );
  return (
    <TenantContext.Provider
      value={{
        tenant: useTenantQuery.data,
        error: useTenantQuery.error,
        isTenantLoading: useTenantQuery.isFetching,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

const useTenantDetails = (): TenantContext => useContext(TenantContext);
export { TenantProvider, useTenantDetails };
