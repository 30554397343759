import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components/native";
import { QueryKey } from "../../../../../services/api/query";

import { ScreenContainer } from "../../../../layout/ScreenContainer";
import Button from "../../../../atoms/Button";
import { Footer } from "../../../../molecules/Footer";
import { HCenterStack } from "../../../../layout/HStack";
import {
  createBasiqBankJobV2,
  getUser,
} from "../../../../../services/userService";
import { User } from "../../../../../dtos/user";
import { ApiError } from "../../../../../services/api/api";
import { getJob } from "../../../../../services/jobService";
import { Job, JobStatus } from "../../../../../dtos/job";
import { appConfig } from "../../../../../config/config";
import { OnboardingMessage } from "../components/OnboardingMessage";
import { Spinner } from "../../../../organisms/Spinner";
import ClientLogo from "../../../../atoms/ClientLogo";
import { authStyles } from "../../../../screens/Auth/shared";
import { ScrollView } from "react-native-gesture-handler";
import { generateNewConsentLink, getActiveConnections } from "../../../../../services/bankService";
import { JobFeedback } from "../../../../organisms/JobFeedback";
import { onBoardingJobStatus } from "../../../../../constants/BasiqJobStatus";
import { useUser } from "../../../../../context/userContext";
import { useActiveConnections } from "../../../../../hooks/useActiveConnections";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

// In this screen we handle the error scenarios
export const BasiqJobStatusSuccess = (): React.ReactElement => {
  const { user: currentUser, isUserLoading } = useUser();
  const [isDataFetching, setIsDataFetching] = useState<boolean>(false);
  const [jobStatusSteps, setJobStatusSteps]: any = useState(onBoardingJobStatus);
  const [currentLoaderIndx, setCurrentLoaderIndx] = useState<number>(0);
  //const [refreshTimer, setRefreshTimer] = useState<Date>(new Date(new Date().getTime() + jobRetryTimeoutInSeconds * 1000));


  const queryClient = useQueryClient();

  const isJobSucceeded =
    currentUser?.basiqJobStatus &&
    currentUser?.basiqJobStatus?.length > 0 &&
    currentUser?.basiqJobStatus.filter((job) => job.status === "success").length ===
    currentUser?.basiqJobStatus.length;

  useEffect(() => {
    console.log('user is updated',)
    checkOnboardStatus();
  }, [currentUser]);

  useEffect(() => {
    if (isUserLoading) {
      jobStatusSteps[currentLoaderIndx].context.status = 'pending';
    } else {
      jobStatusSteps[currentLoaderIndx].context.status = 'success';
    }
    setJobStatusSteps([...jobStatusSteps]);
  }, [isUserLoading]);

  const checkOnboardStatus = async () => {
    if (currentUser?.consent) {
      jobStatusSteps[0].context.status = 'success';
      if (isFetchingActiveConnections) {
        jobStatusSteps[1].context.status = 'pending';
      }
      else {
        if (activeConnections?.length > 0) {
          jobStatusSteps[1].context.status = 'refresh'
          setIsDataFetching(false);
        }
        else {
          jobStatusSteps[1].context.status = 'success';
          setIsDataFetching(true);
          setCurrentLoaderIndx(1);
        }
      }
    }
    else {
      setIsDataFetching(true)
    }

    if (!!currentUser?.consent &&
      !!currentUser?.email &&
      !isFetchingActiveConnections
    ) {
      jobStatusSteps[2].context.status = 'pending';
    }

    setJobStatusSteps([...jobStatusSteps]);
  }

  // called for verify connection
  const { data: activeConnections, isLoading: isFetchingActiveConnections } = useQuery({
    queryKey: [QueryKey.BankConnections],
    queryFn: () => getActiveConnections(),
    enabled: !!currentUser?.isConsent && !isJobSucceeded && !!currentUser?.externalUserId && !currentUser?.isDeactivated,
    onSuccess: (data) => {
      if (data.length > 0) {
        jobStatusSteps[1].context.status = 'refresh'
      }
      else {
        jobStatusSteps[1].context.status = 'success';
        setIsDataFetching(true);
      }
      setCurrentLoaderIndx(1);
    },
    onError: () => {
      jobStatusSteps[1].context.status = 'refresh';
    }
  })

  // Generate new consent link
  const { isFetched: newConsentFetched } = useQuery({
    queryKey: [QueryKey.GenerateNewConsent],
    queryFn: () => generateNewConsentLink(),
    enabled: !!currentUser?.consent && !!currentUser?.email && isDataFetching && !isFetchingActiveConnections,
    onSuccess(data) {
      jobStatusSteps[2].context.status = 'success';
      setJobStatusSteps([...jobStatusSteps]);
      setCurrentLoaderIndx(2);
      //setIsDataFetching(false);
      queryClient.invalidateQueries(QueryKey.User);
    },
    onError: () => {
      jobStatusSteps[2].context.status = 'refresh';
      setJobStatusSteps([...jobStatusSteps]);
      //setIsDataFetching(true);
    },
  })

  // Generating extenalUserID. This should only be called while onboarding for the first time.
  // step 1: for initiate bank connection
  const { data: userDataFromBankConn, isFetching: isUserConcentLinkLoading } = useQuery<User | null, ApiError>({
    queryKey: [QueryKey.createBasiqBankJobV2],
    queryFn: () => createBasiqBankJobV2(),
    enabled: !currentUser?.consent && !!currentUser?.email && isDataFetching,
    onSuccess: (data: any) => {
      jobStatusSteps[0].context.status = 'success';
      setCurrentLoaderIndx(0);
      // jobStatusSteps[1].context.status = 'success';
      // jobStatusSteps[2].context.status = 'pending';
      setJobStatusSteps([...jobStatusSteps]);
      queryClient.invalidateQueries(QueryKey.User);
    },
    onError: (err: any) => {
      setIsDataFetching(false);
      jobStatusSteps[0].context.status = 'refresh';
      setJobStatusSteps([...jobStatusSteps]);
    }
  });

  const onConsentFailed = (step?: number) => {
    if (step === 1) {
      jobStatusSteps[1].context.status = 'pending'
      queryClient.invalidateQueries(QueryKey.User);
      setJobStatusSteps([...jobStatusSteps]);
      return
    }
    window.location.reload();
  }

  if (!currentUser || isUserConcentLinkLoading) {
    return <Spinner />;
  }

  return (
    <ScrollView style={{ backgroundColor: appConfig.dashboardBackground, }}>
      <ScreenContainer center>
        <HCenterStack style={authStyles.imageContainer}>
          <View style={{ width: "70%" }}>
            <ClientLogo />
          </View>
        </HCenterStack>

        {/* {userDataFromBankConn ?
          <OnboardingMessage user={userDataFromBankConn} />
          :
          <OnboardingMessage user={currentUser} />
        } */}
        <OnboardingMessage user={currentUser} />

        <JobFeedback
          steps={jobStatusSteps}
          description="We are setting up your account."
          onStepFailed={onConsentFailed}
          descriptionCenter={true}
        />

        <View style={styles.buttonContainer}>
          <Button
            colour={!newConsentFetched ? "gray" : undefined}
            label="Easy! Lets Begin"
            disabled={
              !newConsentFetched ||
              isUserLoading ||
              (isFetchingActiveConnections || activeConnections?.length > 0)
            }
            onPress={async () => {
              window.open(currentUser?.consent, "_self");
            }}
          />
        </View>
      </ScreenContainer>
      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScrollView>
  )
};

const styles = {
  buttonContainer: [{ marginTop: 50, paddingHorizontal: 20 }],
};
