import React from "react";
import { Text, View, Platform } from "react-native";
import Collapsible from "react-native-collapsible";
import { Switch } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import Card from "../atoms/Card";
import { MediumText } from "../atoms/MediumText";
import { appConfig } from "../../config/config";

const AccordionCard = ({
  children,
  checked,
  toggleSwitch,
  style,
  title,
  subTitle,
  isDisabled
}: {
  children?: React.ReactNode;
  checked: boolean;
  toggleSwitch: () => void;
  style: Record<string, string> | Record<string, string>[];
  title: string;
  subTitle?: string;
  isDisabled?: boolean
}): React.ReactElement => {
  return (
    <Card style={[t.p5, style]}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text style={styles.title}>{title}</Text>
        <Switch {...Platform.select({
          web: {
            activeThumbColor: appConfig.primaryColour
          }
        })} style={t.mB2} value={checked} onValueChange={toggleSwitch} disabled={isDisabled ?? false}
          trackColor={{ true: `${appConfig.primaryColour}80`, false: '#939393' }} />
      </View>
      {subTitle ?
        <View>
          <MediumText >{subTitle}</MediumText>
        </View>
        : null}
      <Collapsible collapsed={!checked} align="top">
        {children}
      </Collapsible>
    </Card>
  );
};

const styles = {
  bodyBackground: {
    overflow: "hidden",
  },
  title: [t.mB2, { fontWeight: "600" }],
};

export default AccordionCard;
