import { getAuth } from "firebase/auth";
import { firebaseApp } from "./config/firebase";

async function hasAuthToken(): Promise<boolean> {
  const auth = getAuth(firebaseApp);
  const firebaseUser = auth.currentUser;
  const maybeAuthToken = await firebaseUser?.getIdToken();

  return !!maybeAuthToken;
}

export default {
  hasAuthToken,
};
