import React, { useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { t } from "react-native-tailwindcss";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { AppScreensParamList } from "../../../../navigation/app-screens";
import Button from "../../../atoms/RoundedButtons";
import { HCenterStack, HStack } from "../../../layout/HStack";
import { MediumText } from "../../../atoms/MediumText";
import Card from "../../../atoms/Card";
import { LinkAccount, EnableLinkAccount } from "../../../../dtos/job";
import AccordionCard from '../../../organisms/AccordionCard';
import { linkedSavingsAccount, updateIslinkedSavingsAccEnabled } from "../../../../services/userService";
import { QueryKey } from "../../../../services/api/query";
import { useUser } from '../../../../context/userContext';

export const LinkedSavingsAccount = ({
  linkedData,
  isFetching,
}: {
  linkedData?: LinkAccount;
  isFetching: boolean;
}): React.ReactElement => {
  // const { accounts } = useAccounts();
  const { user } = useUser();
  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const [isLinkedAccActive, setIsLinkedAccActive] = useState<boolean>(
    user?.savingsAccountEnabled ?? false
  );
  const queryClient = useQueryClient();
  const linkedAccUpdateData = {} as EnableLinkAccount;

  useEffect(() => {
    setIsLinkedAccActive(user?.savingsAccountEnabled ?? false);
  }, [user]);

  const toggleSwitch = async () => {
    if (isLinkedAccActive) {
      setIsLinkedAccActive(false);
      linkedAccUpdateData.savingsAccountEnabled = false
      updateLinkedSavingAcc(linkedAccUpdateData);
    } else {
      setIsLinkedAccActive(true);
      linkedAccUpdateData.savingsAccountEnabled = true
      updateLinkedSavingAcc(linkedAccUpdateData);
    }
  };

  const abstractAccountNumber = (accountNumber: string) => {
    if (accountNumber.length > 4) {
      return `XXXX-${accountNumber.slice(
        accountNumber.length - 4,
        accountNumber.length
      )}`;
    }
    return `XXXX-XXXX`;
  };

  const mutation = useMutation(updateIslinkedSavingsAccEnabled, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKey.UpdateIslinkedSavingsAccEnabled);
    },
    onError: (error: any) => { },
  });

  const updateLinkedSavingAcc = async ({ savingsAccountEnabled }: EnableLinkAccount) => {
    mutation.mutate({ savingsAccountEnabled });
  };

  return (
    <AccordionCard
      checked={isLinkedAccActive}
      toggleSwitch={toggleSwitch}
      style={[t.mB6, t.bgWhite, t.shadowXl]}
      title={`Linked Savings Accounts ${isLinkedAccActive ? 'on' : 'off'}`}
      subTitle={"Link a savings account for your roundups to be deposited into monthly"}
    >
      {/* <ScrollView contentContainerStyle={{ maxHeight: 100 }}>
        {accounts
          ?.filter((a) => a.activeRoundUpsEnabled)
          .map((account, i) => (
            <MediumText key={i.toString()}>
              {account.name}: {account.accountNumber}
            </MediumText>
          ))}
      </ScrollView> */}
      {isFetching && !linkedData ? (
        <ActivityIndicator />
      ) : (linkedData) &&
        (Object.keys(linkedData).length > 0) ? (
        <MediumText center>
          {linkedData!.accountName} -{" "}
          {abstractAccountNumber(linkedData!.accountNumber)}
        </MediumText>
      ) : (
        <MediumText center>Setup your linked savings account</MediumText>
      )}
      <HStack style={[t.pT4, { justifyContent: 'center' }]}>
        <View style={{ width: '70%' }}>
          <Button
            label="Manage Linked Account"
            onPress={() => navigation.navigate("LinkedSavingsAccount")}
          />
        </View>
      </HStack>
    </AccordionCard>
  );
};

const styles = {
  card: [t.flex, t.flexCol, t.p5, t.mB6, t.bgWhite],
};
