import { Ionicons, Entypo } from "@expo/vector-icons";
import React, { useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import { t } from "react-native-tailwindcss";
import { JobStatus } from "../../dtos/job";
import { UseJobHookInfo } from "../../hooks/useJob";
import { MediumText } from "../atoms/MediumText";
import { HStack } from "../layout/HStack";
import RoundedButtons from "../atoms/RoundedButtons";

type Props = {
  steps: { title: string; context: UseJobHookInfo | { status: any } }[];
  description: string;
  descriptionCenter?: Boolean;
  onStepFailed?: (stepIndex: any) => void;
};

const StatusIcon = ({ status, onRefresh = () => { } }: { status?: JobStatus, onRefresh: any }) => {
  switch (status) {
    case JobStatus.Success:
      return <Ionicons name="checkmark-sharp" size={24} color="#52db3e" />;
    case JobStatus.Fail:
      return <Entypo name="cross" size={24} color={styles.warningColor} />;
    case JobStatus.Pending:
      return <ActivityIndicator size={24} />;
    case JobStatus.Wait:
      return <ActivityIndicator size={24} color={'transparent'} />;
    case JobStatus.Refresh:
      return (
        <HStack style={{ alignItems: 'center' }}>
          <RoundedButtons
            colour={'#ff6347 '}
            label={'Refresh'}
            onPress={onRefresh}
            style={styles.refreshButton}
            startIcon={<Ionicons name="md-refresh-sharp" size={18} color={'#FFFFFF'} style={styles.refreshIcon} />}
          />
        </HStack>
      );
    default:
      return <ActivityIndicator size={24} />
  }
};

export const JobFeedback = ({
  steps,
  description,
  descriptionCenter,
  onStepFailed = (stepIndex: number) => { }
}: Props): React.ReactElement => {

  const onRefreshPressed = (stepIndex: number) => {
    onStepFailed(stepIndex);
  }

  return (
    <>
      <MediumText style={[{ paddingBottom: 30 }, !!descriptionCenter ? { textAlign: 'center' } : null]}>{description}</MediumText>
      {steps && steps.map((step: any, index: number) => (
        <View key={index}>
          <HStack style={{
            marginBottom: 8, justifyContent: "center",
            alignItems: 'center'
          }}>
            <HStack>
              <Ionicons
                name="lock-closed"
                size={20}
                color={styles.warningColor}
                style={[t.mR2, t._mT1]}
              />
              <MediumText style={{ marginRight: '8px', paddingBottom: 0, minWidth: step?.stepLength ? step?.stepLength : '158px' }}>{step.title}</MediumText>
            </HStack>
            <View style={styles.iconContainer}>
              <StatusIcon status={step.context.status} onRefresh={() => onRefreshPressed(index)} />
            </View>
          </HStack>
        </View>
      ))}
    </>
  );
};

const styles = {
  warningColor: "#DB3E52",
  buttonContainer: [
    {
      alignItems: 'center',
      paddingBottom: 8,
    }
  ],
  refreshButton: [{
    padding: 6,
    width: 84,
    flexDirection: "row",
  }],
  iconContainer: [{
    minWidth: 84,
    textAlign: 'center',
    minHeight: 32,
    justifyContent: 'center',
  }],
  refreshIcon: [{
    paddingRight: 4,
  }],
};

