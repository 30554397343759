import React, { ReactNode, useContext, useState } from "react";
import { ApiError } from "../services/api/api";

type ErrorContext = {
  criticalError?: (Error & ApiError) | null;
  setCriticalError: (error?: any) => void;
};

const ErrorContext = React.createContext<ErrorContext>({} as ErrorContext);

const ErrorProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider
      value={{
        criticalError: error,
        setCriticalError: setError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

const useError = (): ErrorContext => useContext(ErrorContext);
export { ErrorProvider, useError };
