import React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitHandler,
} from "react-hook-form";
import { Text, View, ViewStyle } from "react-native";
import { CheckBox } from "react-native-elements";
import { t } from "react-native-tailwindcss";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import { authStyles } from "../shared";
import { AuthScreensParamList } from "../../../../navigation/auth-screens";
import { AccountInfoFormData, CustomErrors } from "./RegistrationScreen";
import { VStack } from "../../../layout/VStack";
import { HStack } from "../../../layout/HStack";
import { FormStage } from "./registration-form-stage";
import { appConfig } from "../../../../config/config";
import { Dropdown } from "../../../atoms/Dropdown";
import { PhoneNumberUtil } from 'google-libphonenumber';

type Props = {
  onSubmit: (data: AccountInfoFormData) => Promise<void>;
  customErrors: CustomErrors;
  setCustomErrors: (e: CustomErrors) => void;
  setFormStage: (f: FormStage) => void;
  termsAgree: boolean;
  setTermsAgree: (v: boolean) => void;
  control: Control<AccountInfoFormData>;
  errors: DeepMap<AccountInfoFormData, FieldError>;
  handleSubmit: <TSubmitFieldValues extends FieldValues = AccountInfoFormData>(
    onValid: SubmitHandler<TSubmitFieldValues>,
  ) => (e?: any) => Promise<void>;
};

const countryCodes = [
  { _id: "AU", name: "AU - Australia" },
  { _id: "NZ", name: "NZ - New Zealand" }
];

export const AccountInfoForm = ({
  onSubmit,
  customErrors,
  setCustomErrors,
  setFormStage,
  termsAgree,
  setTermsAgree,
  control,
  errors,
  handleSubmit,
}: Props): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<AuthScreensParamList>>();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const runCustomValidation = (data: AccountInfoFormData) => {
    let isValid = true;
    const newCustomErrors: CustomErrors = {
      ...customErrors,
      general: undefined,
    };

    if (Number(data.mobile)) {
      const isPhoneValid = phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(data.mobile, data.country),
        data.country
      )

      if (isPhoneValid) {
        newCustomErrors.mobilePatternMatch = false;
      } else {
        newCustomErrors.mobilePatternMatch = true;
        isValid = false;
      }
    } else {
      newCustomErrors.mobilePatternMatch = true;
      isValid = false;
    }



    if (
      appConfig.features.signupRequiresContributionLimit &&
      Number.isNaN(data.contributionLimit)
    ) {
      newCustomErrors.contributionLimit = true;
      isValid = false;
    } else {
      newCustomErrors.contributionLimit = false;
    }

    if (
      appConfig.features.signupRequiresCustomerReference &&
      !data.customerReference
    ) {
      newCustomErrors.customerReference = true;
      isValid = false;
    } else {
      newCustomErrors.customerReference = false;
    }

    if (!termsAgree) {
      newCustomErrors.termsAgree = true;
      isValid = false;
    } else {
      newCustomErrors.termsAgree = false;
    }

    if ((data.password && data.confirmPassword)
      && (data.password !== data.confirmPassword)) {
      newCustomErrors.passwordMatch = true;
      isValid = false;
    } else {
      newCustomErrors.passwordMatch = false;
    }

    setCustomErrors(newCustomErrors);
    return isValid;
  };

  const validate = (data: AccountInfoFormData): boolean => {
    data.mobile = data.mobilePrefix + data.mobile;
    return runCustomValidation(data);
  };

  const onValueChangeHandler = (onChange: (...event: any[]) => void, text: string, error?: {}) => {
    onChange(text);
    if (text === "" && error) {
      setCustomErrors({ ...customErrors, ...error })
    }
  }

  const onValueChangeForCountry = (onChange: (...event: any[]) => void, text: string, error?: {}) => {
    onChange(text);
    if (text === "" && error) {
      setCustomErrors({ ...customErrors, ...error })
    }

    if (text == countryCodes[0]._id) {
      control.setValue('mobilePrefix', '+61')
    } else {
      control.setValue('mobilePrefix', '+64')
    }

  }

  return (
    <>
      <View style={styles.controller}>
        <Controller
          name="firstName"
          control={control}
          render={({ onChange, value }) => (
            <Input
              error={errors.firstName ? "This is required." : undefined}
              onChangeText={(text: string) => onValueChangeHandler(onChange, text)}
              value={value}
              placeholder="First Name"
            />
          )}
          rules={{
            required: true,
          }}
        />
      </View>

      <View style={styles.controller}>
        <Controller
          name="lastName"
          control={control}
          render={({ onChange, value }) => (
            <Input
              error={errors.lastName ? "This is required." : undefined}
              onChangeText={(text: string) => onValueChangeHandler(onChange, text)}
              value={value}
              placeholder="Last Name"
            />
          )}
          rules={{
            required: true,
          }}
        />
      </View>


      <View style={styles.controller}>
        <Controller
          name="email"
          control={control}
          render={({ onChange, value }) => (
            <Input
              error={errors.email ? "This is required." : undefined}
              onChangeText={(text: string) => onValueChangeHandler(onChange, text)}
              value={value}
              placeholder="Email"
            />
          )}
          rules={{
            required: true,
          }}
        />
      </View>

      <View style={styles.controller}>
        <Controller
          name="password"
          control={control}
          render={({ onChange, value }) => (
            <Input
              error={errors.password ? "This is required." : undefined}
              onChangeText={(text: string) => onValueChangeHandler(onChange, text)}
              value={value}
              placeholder="Password"
              secureTextEntry
            />
          )}
          rules={{
            required: true,
          }}
        />
      </View>

      <View style={styles.controller}>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ onChange, value }) => (
            <Input
              error={errors.confirmPassword ? "This is required." : undefined}
              onChangeText={(text: string) => onValueChangeHandler(onChange, text, { passwordMatch: false })}
              value={value}
              placeholder="Confirm Password"
              secureTextEntry
            />
          )}
          rules={{
            required: true,
          }}
        />
        {customErrors.passwordMatch && !errors.confirmPassword && (
          <Text style={authStyles.error}>Must match password.</Text>
        )}
      </View>

      <View style={[{ display: "flex", flexDirection: "row" }, styles.controller]}>
        <View style={{ flex: 1, width: "90%" }}>
          <Controller
            name="mobilePrefix"
            defaultValue="+61"
            control={control}
            render={({ value }) => (
              <Input style={{ width: "90%" }} value={value} editable={false} />
            )}
            rules={{
              required: true,
            }}
          />
        </View>

        <View style={{ flex: 5 }}>
          <Controller
            name="mobile"
            control={control}
            style={{ flex: 2 }}
            render={({ onChange, value }) => (
              <Input
                error={errors.mobile ? "This is required." : undefined}
                onChangeText={(text: string) => onValueChangeHandler(onChange, text, { mobilePatternMatch: false })}
                value={value}
                placeholder="Mobile"
              />
            )}
            rules={{
              required: true,
            }}
          />
        </View>
      </View>
      {customErrors.mobilePatternMatch && !errors.mobile && (
        <Text style={[authStyles.error]}>
          {/* Should start with +61 and be followed by min 9 chars. */}
          Phone number is invalid please enter a valid number
        </Text>
      )}

      <View style={styles.controller}>
        <Controller
          name="country"
          control={control}
          render={({ onChange, value }) => (
            <Dropdown
              items={countryCodes}
              onValueChange={(value) => {
                onValueChangeForCountry(onChange, value);
              }}
              selectedValue={value}
            />
          )}
          rules={{
            required: true,
          }}
        />
      </View>

      <CheckBox
        title={
          <Text>
            I agree to the{" "}
            <Text
              style={authStyles.coloredLink}
              onPress={e => {
                navigation.navigate("Terms");
                e.preventDefault();
              }}
            >
              terms and conditions
            </Text>
          </Text>
        }
        checked={termsAgree}
        onPress={() => {
          setTermsAgree(!termsAgree);
        }}
        containerStyle={{
          borderWidth: 0,
          padding: 0,
          marginTop: 8,
          marginBottom: 16,
          marginLeft: 0,
        }}
        textStyle={[t.textLg, t.fontNormal, t.mL2, t.mR1, t.textGray600]}
      />
      {customErrors.termsAgree && (
        <Text style={[authStyles.error, t._mT2, t.mB3]}>
          Please accept the terms and conditions.
        </Text>
      )}

      <VStack>
        <Button
          onPress={handleSubmit(data => {
            if (validate(data)) {
              onSubmit(data);
            }
          })}
          label="Continue"
        />
        {customErrors.contributionLimit && (
          <Text style={[authStyles.error, t.mT2]}>
            Something went wrong with your referral link and we can't determine
            your Milton Graham debt amount. Please try following the referral
            link again.
          </Text>
        )}
        {customErrors.customerReference && (
          <Text style={[authStyles.error, t.mT2]}>
            Something went wrong with your referral link and we can't determine
            your Milton Graham debtor specific payment reference number. Please
            try following the referral link again.
          </Text>
        )}
      </VStack>
    </>
  );
};

const styles = {
  controller: {
    paddingVertical: 4,
  }
};
