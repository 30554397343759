import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { ConnectBankScreen } from "../../components/screens/Onboarding/ConnectBank/ConnectBankScreen";
import { RoundupAccountsSetupScreen } from "../../components/screens/Onboarding/RoundupAccountsSetup/RoundupAccountsSetupScreen";
import { FundingAccountSetupScreen } from "../../components/screens/Onboarding/FundingAccountSetup/FundingAccountSetupScreen";
import { FinishOnboardingScreen } from "../../components/screens/Onboarding/FinishOnboarding/FinishOnboardingScreen";
import { TermsScreen } from "../../components/screens/Shared/Terms/TermsScreen";
import { PrivacyPolicyScreen } from "../../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { HelpScreen } from "../../components/screens/Shared/Help/HelpScreen";

const onboardingScreenList: IScreenRoute[] = [
  {
    name: "AccountSetup",
    component: ConnectBankScreen,
    options: {
      title: "Account Setup",
    },
  },
  {
    name: "RoundupAccountsSetup",
    component: RoundupAccountsSetupScreen,
    options: {
      title: "Roundup Accounts",
    },
  },
  {
    name: "FundingAccountSetup",
    component: FundingAccountSetupScreen,
    options: {
      title: "Funding Accounts",
    },
  },
  {
    name: "FinishOnboarding",
    component: FinishOnboardingScreen,
    options: {
      title: "Finish Onboarding",
    },
  },
  {
    name: "Terms",
    component: TermsScreen,
    options: {
      title: "Terms & Conditions",
    },
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    options: {
      title: "Privacy Policy",
    },
  },
  {
    name: "Help",
    component: HelpScreen,
    options: {
      title: "Help",
    },
  },
];

const OnboardingStackRoot =
  createStackNavigator<OnboardingRootStackParamList>();

export const OnboardingStack = () => (
  <OnboardingStackRoot.Navigator
    initialRouteName="AccountSetup"
    screenOptions={{ headerShown: false }}
  >
    {onboardingScreenList.map(({ name, component, options }, index) => (
      <OnboardingStackRoot.Screen
        key={index}
        name={name as keyof OnboardingRootStackParamList}
        component={component}
        options={options}
      />
    ))}
  </OnboardingStackRoot.Navigator>
);
