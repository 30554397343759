import { appConfig } from "../config/config";
import { Institution } from "../dtos/institution";
import { Job, JobStatus, JobType } from "../dtos/job";
import { Transaction } from "../dtos/transaction";
import utils from "../utils";
import api from "./api/api";

export type CreateConnectionRequest = {
  institutionSlug: string;
  username: string;
  password: string;
};

export type GetAccountsResponse = {
  accounts: BankAccount[];
};

export type SyncAccountsResponse = {
  accounts: BankAccount[];
};

export type updateBasiqBankAccount = {
  accountId: string,
  activeRoundUpsEnabled: boolean
}

export type BankAccount = {
  _id: string;
  id: string; // todo reconcile the fact that this is the bankfeeds id vs the above mongo id, and dif ones are used in dif versions of the api
  externalUserId: string;
  institution: string;
  accountHolderName: string;
  accountNumber: string;
  name: string;
  bsb: string;
  balance: string;
  availableBalance: string;
  accountType: string;
  status: string;
  activeDebitEnabled: boolean;
  activeRoundUpsEnabled: boolean;
};

export type DeleteConsent = {
  status: string;
  type: string;
  userId: string;
  _createdDate: string;
  _flags: any;
  _id: string;
  id: string;
}

export const getInstitutions = async (): Promise<Institution[]> => {
  return api.get<Institution[]>(`bank/${appConfig.tenant}/banks`);
};

export const createConnectionJob = async ({
  institutionSlug,
  username,
  password,
}: CreateConnectionRequest): Promise<Job> => {
  return api.post<Job>(`bank/${appConfig.tenant}/banks/user/connect`, {
    institutionSlug,
    username,
    password,
  });
};

// todo convert to queued job structure if this is ever used
export const deleteConnection = async (
  externalUserId: string
): Promise<void> => {
  return api.delete<void>(
    `bank/${appConfig.tenant}/banks/user/connections/${externalUserId}`
  );
};

export const getTransactions = async (): Promise<Transaction[]> => {
  return api.get<Transaction[]>(
    `bank/${appConfig.tenant}/banks/user/transactions`
  );
};

export const getAccounts = async (): Promise<BankAccount[] | null> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return (
    (
      await api.get<GetAccountsResponse>(
        `bank/${appConfig.tenant}/banks/user/accounts`
      )
    ).accounts ?? []
  );
};

export const syncAccountsJob = async (): Promise<Job> => {
  return api.get<Job>(`bank/${appConfig.tenant}/banks/user/accounts/sync`);
};

export const syncBasiqAccountsJob = async (): Promise<Job> => {
  return api.get<Job>(
    `basiq-bank/${appConfig.tenant}/banks/user/accounts/sync`
  );
};

export const syncBasiqAccountsJobV2 = async (): Promise<any> => {
  return api.get<any>(
    `basiq-bank/${appConfig.tenant}/basiq-user/accounts/sync`
  );
};

export const updateAccount = async ({
  accountId,
  activeRoundUpsEnabled
}: updateBasiqBankAccount): Promise<updateBasiqBankAccount> => {
  return api.post<updateBasiqBankAccount>(
    `basiq-bank/${appConfig.tenant}/banks/user/accounts/${accountId}`,
    {
      activeRoundUpsEnabled,
    }
  );
};

export const basiqUpdateAccount = async ({
  accountId,
  activeRoundUpsEnabled
}: updateBasiqBankAccount): Promise<updateBasiqBankAccount> => {
  return api.post<updateBasiqBankAccount>(
    `basiq-bank/${appConfig.tenant}/banks/user/accounts/${accountId}`,
    {
      activeRoundUpsEnabled,
    }
  );
};

export const deleteUserConsent = async (): Promise<any> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;
  return api.delete<DeleteConsent>(
    `basiq-bank/${appConfig.tenant}/basiq-user/consent/delete`
  );
};

export const getActiveConnections = async () => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;
  return api.get(`basiq-bank/${appConfig.tenant}/banks/user/connections`);
};

export const generateNewConsentLink = async (action: String | void) => {
  let actionParam = ''
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;
  if (action) {
    actionParam += `?action=${action}`
  }
  return api.get(`basiq-bank/${appConfig.tenant}/basiq-user/consent/create${actionParam}`);
}